import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ParamsService } from './params.service';
import { LoginResponse } from '../models/login-response.model';
import { EnvVars } from '../models/envvars.model';
import { BydCountry } from '../models/byd-country.model';
import { SessionStorageService } from './session-storage.service';
import { WindowRefService } from './window-ref.service';
import { AuthorizationEntry } from '../models/authorization-entry.model';
import { LoginService } from './login.service';
import { HttpRequestService } from './http-request.service';
import { HttpResponseService } from './http-response.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static DEBUG_MODE = true;
  private params: EnvVars;
  private accessToken: string;
  constructor(@Inject(BydCountry) bydCountryConfig: BydCountry,
    private byDCountryConfig: BydCountry,
    private http: HttpClient,
    private router: Router,
    private paramsService: ParamsService,
    private windowRef: WindowRefService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private httpRequestService: HttpRequestService,
    private httpResponseService: HttpResponseService) {
    this.params = paramsService.getParams(); 
  }

  public getServiceBaseURL(): string {
    return this.params.SERVICE_BASE_URL;
  }

  private getCloudSSOBaseURL(): string {
    return this.params.CLOUDSSO_BASE_URL;
  }

  private getBaseURL(): string {
    return this.getServiceBaseURL() + '/oauth/v1';
  }

  private getRevokeTokensURL(): string {
    return this.getBaseURL() + '/revoke';
  }

  public isLoggedIn(): boolean {
    return !!this.sessionStorageService.getIsLoggedIn();
  }

  public getLogonID(): AuthorizationEntry {
    return this.sessionStorageService.getAuthorizationEntry();
  }

  public updateParams(updatedParams: EnvVars): void {
    this.params = updatedParams;
  }

  public getParams(): EnvVars {
    return this.params;
  }

  public exchangeToken(authCode: string): Observable<LoginResponse> {
    const codeVerifier = this.sessionStorageService.getCodeVerifierToken();
    const tokenURL = this.getCloudSSOBaseURL() + '/token';

    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('scope', 'HC.Request.AllScopes')
      .set('code', authCode)
      .set('client_id', this.params.APP_CLIENT_ID)
      .set('code_verifier', codeVerifier)
      .set('redirect_uri', this.params.APP_LOGIN_REDIRECT_URL);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json' + (AuthService.DEBUG_MODE ? ';odata=verbose' : '')
    });

    // remove code verifier token
    this.sessionStorageService.removeCodeVerifierToken();

    return this.http
      .post<LoginResponse>(tokenURL, body.toString(), {
        headers,
      })
      .pipe(
        map(res => this.extractAccessToken(res)),
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.httpResponseService.showTimeoutMessage();
          }
          return throwError(err.status);
        }));
  }


  public revokeAccessToken(): Observable<any> {
    this.sessionStorageService.removeAllSessions();
    this.localStorageService.clearAll();
    const body = new HttpParams()
      .set('acctoken', 'invalidate')
      .set('ssotoken', 'invalidate')
      .set('client_id', this.params.APP_CLIENT_ID)
      .set('access_token', this.sessionStorageService.getAccessToken());

    return this.httpRequestService.postWithToken<void>(this.getRevokeTokensURL(), body.toString())
      .pipe(
        catchError(err => {
          return null;
        }
        ));
  }

  private extractAccessToken(response: LoginResponse): LoginResponse {
    this.sessionStorageService.setIsLoggedIn('true');
    this.sessionStorageService.setAccessToken(response.access_token);
    this.sessionStorageService.setAuthorizationEntry(response.subscription_info.AuthorizationEntryList[0]);
    this.sessionStorageService.setCountry(response.subscription_info.Country);
    this.sessionStorageService.setCustomerId(response.subscription_info.CustomerID);
    this.sessionStorageService.setUserName(response.subscription_info.LogonID);
    this.sessionStorageService.setCloudId(response.subscription_info.UID);

    setTimeout(() => this.httpResponseService.showTimeoutMessage(), (response.expires_in - 60) * 1000);
    return response;
  }

  public validateAccessToken(): Observable<boolean> {
    if (!this.sessionStorageService.getAccessToken()) {
      return Observable.create((observer) => {
        observer.next(false);
      });
    }

    return Observable.create((observer) => {
      observer.next(true);
    });

  }
}
