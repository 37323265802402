import {Injectable} from '@angular/core';
import {SessionTimeoutModalComponent} from '../session-timeout-modal/session-timeout-modal.component';
import {BsModalService} from 'ngx-bootstrap';
import {LoginService} from './login.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseService {

  constructor(
    private modalService: BsModalService,
    private loginService: LoginService,
    private sessionStorageService: SessionStorageService) {
  }

  public showTimeoutMessage() {

    this.modalService.show(SessionTimeoutModalComponent, {
      backdrop: 'static',
      class: 'modal-md modal-dialog-centered',
      keyboard: false,
      initialState: {
        header: 'MESSAGE.SESSION_TIMEOUT.HEADER',
        title: 'MESSAGE.SESSION_TIMEOUT.TITLE',
        message: 'MESSAGE.SESSION_TIMEOUT.SUBTITLE',
        positiveButtonName: 'MESSAGE.SESSION_TIMEOUT.ACTION',
        positiveButtonHandler: () => this.loginService.login(),

      }
    });
  }
}
