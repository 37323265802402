import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmittalsProductDocumentRoutingModule } from './submittals-product-document-routing.module';
import { SubmittalsProductDocumentComponent } from './submittals-product-document.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubmittalsProductDocumentComponent],
  imports: [
    CommonModule,
    SubmittalsProductDocumentRoutingModule,
    SharedModule,
    TranslateModule
  ]
})
export class SubmittalsProductDocumentModule { }
