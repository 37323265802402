import { Component, OnInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { delay } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service'
import { ParamsService } from 'src/app/shared/services/params.service';
import { Subscription } from 'rxjs';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
    selector: 'app-submittals-product-sections',
    templateUrl: './submittals-product-sections.component.html',
    styleUrls: ['./submittals-product-sections.component.scss'],
})
export class SubmittalsProductSectionsComponent implements OnInit, OnDestroy, AfterContentChecked {

    public stepperObject: Array<any> = [];
    public productSections: any = {};
    public imgBaseURL: string = '';
    public spinner$: boolean = false;
    public error$: boolean = false;
    private selectedByProductObject: Array<any> = [];
    private params: any = {};
    private observeStepperObj: Subscription;
    private observeSectionObj: Subscription;
    private observeRoute: Subscription;
    public parenterrormessage: object = {};

    ngAfterContentChecked(): void {
        /* if in case the stepper progress count goes below 1, set it back to 1 at this component. */
        let routerURL = '/product-category-section/' + this.activatedRoute.snapshot.params['pcsid'];

        this.stepperObject = this.dataService.onAfterContentChecked(this.selectedByProductObject, this.stepperObject, 'STEPPER.SECTIONS', routerURL);

        /* if sub category exists and no subcategory is selected set an empty object */
        if (this.selectedByProductObject.filter(obj => obj.TITLE === 'STEPPER.SECTIONS').length === 0) {
            let tempByProductObject = {
                accId: 2,
                TITLE: 'STEPPER.SECTIONS',
                selectedItems: []
            };

            this.selectedByProductObject.splice(2, this.selectedByProductObject.length);

            this.selectedByProductObject.push(tempByProductObject);

            this.sessionStorageService.setByProductObject(this.selectedByProductObject)
        }
    }

    constructor(
        public activatedRoute: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private httpRequestService: HttpRequestService,
        private sessionStorageService: SessionStorageService,
        private paramsService: ParamsService,
        public config: LanguageConfig,
        private tagService: GoogleTagManagerService
    ) {
        this.params = paramsService.getParams() || {};
        // here we are subscribing to the global stepper object to update the steps in stepper component.
        this.observeStepperObj = this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = c;
            });
        this.observeSectionObj = this.dataService.currentSectionsOrProductsObject
            .pipe(delay(0))
            .subscribe((obj: any) => {
                this.imgBaseURL = obj.sections ? obj.sections.assetHostName : '';
                this.productSections = obj.sections || [];

                if (!(this.productSections.sections && this.productSections.sections.length > 0)) {
                    this.observeRoute = this.activatedRoute.params.subscribe(params => {
                        let subChapterId = params['pcsid'];
                        let chapterId = this.activatedRoute.snapshot.parent ? this.activatedRoute.snapshot.parent.parent.params['id'] : '';
                        this.loadProductSections(chapterId, subChapterId);
                    })
                }
            });
    }

    ngOnInit() {
        this.selectedByProductObject = this.sessionStorageService.getByProductObject();
    };

    loadProductSections(chapterId, subChapterId) {
        this.spinner$ = true;
        this.httpRequestService.getWithToken(this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/chapters/' + chapterId + '/subChapters/' + subChapterId + '/sections')
            .subscribe(((obj: any) => {
                this.spinner$ = false;
                this.imgBaseURL = obj.assetHostName;
                this.productSections = obj;
            }),
                (err => {
                    this.spinner$ = false;
                    this.error$ = true;
                    this.parenterrormessage = err;
                }))
    }
    // 'nextToDocument' will change the stepper behavior obeject and then navigate to the next step 
    nextToProducts(id, selectedProdSectionObj) {
        selectedProdSectionObj.assetHostName = this.imgBaseURL;
        let tempByProductObject = {
            accId: 2,
            TITLE: 'STEPPER.SECTIONS',
            selectedItems: [selectedProdSectionObj]
        };

        this.selectedByProductObject.splice(2, this.selectedByProductObject.length);

        this.selectedByProductObject.push(tempByProductObject);

        this.sessionStorageService.setByProductObject(this.selectedByProductObject);

        let tempSecStep = this.stepperObject.filter(obj => obj.TITLE === 'STEPPER.SECTIONS');

        if (tempSecStep[0] && tempSecStep[0].stepValue) {
            this.stepperObject = this.dataService.changeStepperStepInfo(this.stepperObject, tempSecStep)
        }

        this.dataService.changeStepperObjectGlobal(this.stepperObject);
        const navigationExtras: NavigationExtras = {
            relativeTo: this.activatedRoute
        };

        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step4',
            'pageName': 'Products'
        }, this.selectedByProductObject);


        this.router.navigate(['product-sections', id], navigationExtras);
    };

    ngOnDestroy(): void {
        this.observeStepperObj.unsubscribe();
        this.observeSectionObj.unsubscribe();
        if (this.observeRoute) {
            this.observeRoute.unsubscribe();
        }
    }
}
