import {NgModule} from '@angular/core';
import {RootComponent} from './root/root.component';
import {SharedModule} from '../shared/shared.module';
import {SessionTimeoutModalComponent} from '../shared/session-timeout-modal/session-timeout-modal.component';
import {AuthComponent} from './auth-login/auth.component';

@NgModule({
    declarations: [
        RootComponent,
        AuthComponent
    ],
    imports: [
        SharedModule
    ],
    bootstrap: [
        RootComponent
    ],
    exports: [
        AuthComponent
    ]
})
export class AuthModule {
}
