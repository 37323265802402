import { Injectable } from '@angular/core';
import { EnvVars } from '../models/envvars.model';

@Injectable()
export class ParamsService {
  private params: EnvVars;

  constructor() {
    this.params = window['environmentVariables'] as EnvVars;
  }

  public getParams() {
    return this.params;
  }

  public updateParams(countrycode: string): void {
    if (countrycode === 'ca') {
      this.params.APP_LOGIN_REDIRECT_URL = this.params.APP_LOGIN_REDIRECT_URL_CA;
      this.params.APP_LOGOUT_REDIRECT_URL = this.params.APP_LOGOUT_REDIRECT_URL_CA;
    } 
  }
}