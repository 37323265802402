import { LanguageConfig } from '../models/language-config.model';

const langs = [
    {
        "name": "English",
        "value": "en"
    },
    {
        "name": "English (UK)",
        "value": "en-GB"
    },
    {
        "name": "Dansk",
        "value": "da-DK"
    },
    {
        "name": "Suomi",
        "value": "fi-FI"
    },
    {
        "name": "English (Ireland)",
        "value": "en-IE"
    },
    {
        "name": "Svenska",
        "value": "sv-SE"
    },
    {
        "name": "Español (España)",
        "value": "es-ES"
    },
    {
        "name": "Français (France)",
        "value": "fr"
    },
    {
        "name": "Português (Portugal)",
        "value": "pt-PT"
    },
    {
        "name": "Français (Belgique)",
        "value": "fr-BE"
    },
    {
        "name": "Nederlands (België)",
        "value": "nl-BE"
    },
    {
        "name": "Français (Luxembourg)",
        "value": "fr-LU"
    },
    {
        "name": "Deutsch (Schweiz)",
        "value": "de-CH"
    },
    {
        "name": "Deutsch (Schweiz)",
        "value": "de-CH"
    },
    {
        "name": "Italiano (Switzerland)",
        "value": "it-CH"
    },
    {
        "name": "Deutsch (Deutschland)",
        "value": "de-DE"
    },
    {
        "name": "Nederlands (Nederland)",
        "value": "nl-NL"
    },
    {
        "name": "Polski",
        "value": "pl-PL"
    },
    {
        "name": "Italiano",
        "value": "it-IT"
    },
    {
        "name": "Български",
        "value": "bg-BG"
    },
    {
        "name": "Ελληνικά",
        "value": "el-GR"
    },
    {
        "name": "Română",
        "value": "ro-RO"
    },
    {
        "name": "Slovenščina",
        "value": "sl-SI"
    },
    {
        "name": "Hrvatski",
        "value": "hr-HR"
    },
    {
        "name": "Српски (Bosnia and Herzegovina)",
        "value": "sr-BA"
    },
    {
        "name": "English (Israel)",
        "value": "en-IL"
    },
    {
        "name": "Српски (Serbia)",
        "value": "sr-RS"
    },
    {
        "name": "Čeština",
        "value": "cs-CZ"
    },
    {
        "name": "Magyar",
        "value": "hu-HU"
    },
    {
        "name": "Slovenčina (Slovakia)",
        "value": "sk-SK"
    },
    {
        "name": "Eesti (Estonia)",
        "value": "et-EE"
    },
    {
        "name": "Latviešu",
        "value": "lv-LV"
    },
    {
        "name": "Lietuvių",
        "value": "lt-LT"
    },
    {
        "name": "Русский (Estibua)",
        "value": "ru-EE"
    },
    {
        "name": "Русский (Lithuania)",
        "value": "ru-LT"
    },
    {
        "name": "Русский (Latvia)",
        "value": "ru-LV"
    }
];

let defaultLang = langs[0];
const selectedLanguage = sessionStorage.language;
if (selectedLanguage) {
    defaultLang = langs.find((data: any) => {
        return data.value.toLowerCase() === selectedLanguage.toLocaleLowerCase();
    });
}

langs.sort((lang1, lang2) => lang1.name > lang2.name ? 1 : -1);
export const HiltiLanguageConfig: LanguageConfig = {

    availableLangs: langs,
    defaultLang

};
