import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmittalsProductCategoriesRoutingModule } from './submittals-product-categories-routing.module';
import { SubmittalsProductCategoriesComponent } from './submittals-product-categories.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SubmittalsProductCategoriesResolver } from './submittals-product-categories.resolver.service';

@NgModule({
  declarations: [SubmittalsProductCategoriesComponent],
  imports: [
    CommonModule,
    SubmittalsProductCategoriesRoutingModule,
    SharedModule,
    TranslateModule
  ],
  providers: [SubmittalsProductCategoriesResolver]
})
export class SubmittalsProductCategoriesModule { }
