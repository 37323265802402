import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsCartComponent } from './submittals-cart.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';
import { SubmittalsCartEmptyComponent } from './components/submittals-cart-empty/submittals-cart-empty.component';

const cartRoutes: Routes = [
  {
    path: '', component: SubmittalsCartComponent,
    canDeactivate: [NavigationGuard],
    children: [
      {
        path: 'empty-cart',
        component: SubmittalsCartEmptyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(cartRoutes)],
  exports: [RouterModule]
})
export class SubmittalsCartRoutingModule { }
