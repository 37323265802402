import { Injectable } from '@angular/core';
import { SessionStorageService } from './session-storage.service';
import { DataService } from './data.service';
import { delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GoogleTagManagerService {
    private stepperObject: Array<any> = [];
    private eventLabelArr = ['Firestop', 'Open Menu', 'Access Agreement', 'Privacy Policy', 'Contact Us', 'Logout', 'Success', 'Browser Back'];
    private headerArr = ['HomePage', 'Product Category', 'Product SubCategory', 'Products', 'Documents', 'Submittal Options', 'Summary'];

    constructor(
        private sessionStorageService: SessionStorageService,
        private dataService: DataService
    ) {
        this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = Object.assign([], c);
            });
    }

    /**
     * @method setGMTDataLayer is uset to bind datalayer values in window object.
     * @param dataLayerObj is used for binding datalayer object
     * @param selectedByProductObj is used for get selected by products object details
     */
    setGMTDataLayer (dataLayerObj = {}, selectedByProductObj = []) {
        window['dataLayer'] = window['dataLayer'] || [];

        let loggedInUserName = this.sessionStorageService.getUserName();
        let loggedInCountry = this.sessionStorageService.getCountry();
        let loggedInLanguage = this.sessionStorageService.getLanguage();
        let loggedCloudId = this.sessionStorageService.getCloudId();
        let authorizationEntry = this.sessionStorageService.getAuthorizationEntry();
        let dataLayer = {};
        
        // update user details
        if (loggedInUserName) {
            let domain = loggedInUserName.split('@')[1] || '';
            
            /**
             * Contains current login status.
             */ 
            dataLayer['loggedIn'] = true;
            
            /**
             * Indicates if the user has logged in with a hilti.com email address. 
             */ 
            if (domain === 'hilti.com') {
                dataLayer['hiltiUser'] = true;
            } else {
                dataLayer['hiltiUser'] = false;
            }

            /**
             * Contains the Hilti Cloud user ID. Appears only when loggedIn=true.
             */ 
            if (loggedCloudId) {
                dataLayer['cloudID'] = loggedCloudId;
            }

            if (authorizationEntry) {
                /**
                 * Contains the active account number, that consists of 10 digits. Appears only when loggedIn=true. 
                 */ 
                if (authorizationEntry.CustomerOriginID) {
                    dataLayer['account'] = authorizationEntry.CustomerOriginID;
                }
                
                /**
                 * Taken from SAP. Consists of digits. Appears only when loggedIn=true.
                 */ 
                if (authorizationEntry.ContactOriginID) {
                    dataLayer['contact_id'] = authorizationEntry.ContactOriginID;
                }
            }

            /**
             * the country of the website visited
             */ 
            dataLayer['country'] = loggedInCountry;

            /**
             * the region of the website visited
             */ 
            dataLayer['region'] = 'W1';

            /**
             * the language of the website visited
             */ 
            dataLayer['pageLanguage'] = loggedInLanguage + '-' + loggedInCountry;

            if (!selectedByProductObj.length) {
                selectedByProductObj = this.sessionStorageService.getByProductObject() || [];
            }

            /**
             * Update virtualpageview details in datalayer
             */
            if (this.eventLabelArr.indexOf(dataLayerObj['eventLabel']) === -1) {
                this.setPageViewTag (dataLayerObj, [...selectedByProductObj], {...dataLayer});
            }

            /**
             * Update navigaion event details in datalayer
             */
            if (dataLayerObj['event']) {
                this.setNavigationTag(dataLayerObj, [...selectedByProductObj], {...dataLayer});
            }
        } else {
            /**
             * Update datalayerwhen user doesn't have authentication
             */
            dataLayer['loggedIn'] = false;
            dataLayer['hiltiUser'] = false;
            dataLayer['event'] = 'virtualPageview';

            window['dataLayer'].push(dataLayer);
        }
    }

    /**
     * @method setProductsDetails uses to set products selected on different steps
     * @param selectedObj refers to selcted by products object
     * @param dataLayer refers set variables in datalayer
     */ 
    setProductsDetails (selectedObj, dataLayer) {
        let pathUrl = window.location.pathname;

        if (dataLayer['stepNumber'] === 'Step1') {
            pathUrl = '/by-products';
        }

        if (selectedObj.length) {
            selectedObj.forEach((prod, key) => {
                if (key === 0) {
                    /**
                     * the product category the user selected on step 1.
                     */
                    dataLayer['productCategory'] = prod.name;

                    if (dataLayer['stepNumber'] === 'Step2') {
                        pathUrl += '/' + prod.id;
                    }
                } else if (prod.TITLE === 'STEPPER.PRODUCT_CATEGORY') {
                    /**
                     * the product subcategory the user selected on step 2. For example when on step 2 the user 
                     * selects Foams, the values should be "Foams"
                     */
                    dataLayer['productSubCategory'] = prod.selectedItems[0].name;

                    if (dataLayer['stepNumber'] === 'Step3') {
                        pathUrl += '/product-category-section/' + prod.selectedItems[0].id;
                    } else if (dataLayer['stepNumber'] === 'Step4') {
                        pathUrl += '/product-category/' + prod.selectedItems[0].id;
                    }
                } else if (prod.TITLE === 'STEPPER.SECTIONS') {
                    /**
                     * the product subsubcategory the user selected on step 3. For example when on step 3 the user 
                     * selects Batteries, the values should be "Batteries"
                     */ 
                    if (prod.selectedItems[0]) {
                        dataLayer['productSubSubCategory'] = prod.selectedItems[0].name;

                        if (dataLayer['stepNumber'] === 'Step4') {
                            pathUrl += '/product-sections/' + prod.selectedItems[0].id;
                        }
                    }
                } else if (prod.TITLE === 'STEPPER.PRODUCTS') {
                    /**
                     * the range ID of the products that are selected on step 4 and appear on step 4 separated by "|". 
                     * For example a value could be: r3476|r5456. 
                     */ 
                    let productRangeId = [];

                    prod.selectedItems.forEach(item => {productRangeId.push(item.id); });

                    dataLayer['productRangeId'] = productRangeId.join('|');

                    if (dataLayer['stepNumber'] === 'Step5') {
                        pathUrl += '/product-document/' + productRangeId[0];
                    }
                } else if (prod.TITLE === 'STEPPER.DOCUMENTS') {
                    /**
                     * the list of document IDs that are selected when the visitor is on step 5 , separated by "|".
                     */ 
                    let selectedDocs = [];

                    prod.selectedItems.forEach(prod => {
                        prod.selectedDocuments.forEach(tabProd => {
                            tabProd.selectedPDFs.forEach(x => { 
                                if (x.value === true) {
                                    selectedDocs.push(x._id);
                                }
                            });
                        });
                    });

                    dataLayer['documentID'] = selectedDocs.join('|');

                    if (dataLayer['stepNumber'] === 'Step6') {
                        pathUrl += '/create-submittals';
                    }
                } else if (prod.TITLE === 'STEPPER.OPTIONS') {
                    /**
                     * what the user has selected as delivery method on step 5.
                     */ 
                    dataLayer['deliveryMethod'] = prod.selectedItems[0].selectedDocuments[0].value === 'viewOnline' ? 'Online' : 'Email';

                    /**
                     * the cover the user has selected on step 6.
                     */ 
                    let coverPage = prod.selectedItems[1].selectedDocuments[0].value;
                    dataLayer['coverPage'] = (coverPage === 'hiltiCoverPage') ? 'Hilti' : (coverPage === 'customCoverPage' ? 'Custom' : 'No Cover');

                    /**
                     * the choice of the user regarding pagination on step 6.
                     */ 
                    dataLayer['pagination'] = prod.selectedItems[2].selectedDocuments[0].value === 'yes';

                    if (dataLayer['stepNumber'] === 'Step7') {
                        pathUrl += '/summary';
                    }
                }
            });
        }

        /**
         * would collect the URL of the page the visitor is on or interacting with the website on every 
         * datalayer.push? Please set it in a way that it will not get any external URL parameters
         */ 
        dataLayer['pageURL'] = window.location.origin + pathUrl;

        return dataLayer;
    }

    /**
     * @method updatePageNameStepNumber use to set PageName and Step Number for Virtualpageview and Navigation
     * @param dataLayerObj refers request from the event
     * @param dataLayer refers datalayer details to push
     * @param stepperIndex refers stepper index
     */
    updatePageNameStepNumber (dataLayerObj, dataLayer, stepperIndex: number = 0) {
        let stepNumber = dataLayerObj['stepNumber'];
        let pageName = dataLayerObj['pageName'];

        if (!stepNumber && !pageName) {
            /**
             * when user using menu interaction to navigate to back steps
             */ 
            if (dataLayerObj['stepperObject'] && stepperIndex > 1) {
                if (dataLayerObj['stepperObject'].filter(obj => { return obj.title === 'STEPPER.SECTIONS' }).length == 0) {
                    ++stepperIndex;
                }
            }

            if (stepperIndex >= 0) {
                pageName = this.headerArr[stepperIndex];
                stepNumber = 'Step' + (stepperIndex + 1);
            }
        }

        /**
         * depending on the number of the step the user is. The submission of the request should be 
         * counted as step 8.
         */ 
        if (stepNumber) {
            dataLayer['stepNumber'] = stepNumber;
        }
        
        /**
         * depending on the number of the step the user is.
         */ 
        if (pageName) {
            dataLayer['pageName'] = pageName;
        }

        return dataLayer;
    }

    /**
     * @method setNavigationTag use to set the information of where the button / menu / logo 
     * was click on. On which url, on which pageview, at what step and it give us the information 
     * of what is the user doing at what step of their journey
     * @param dataLayerObj refers input from different components
     * @param selectedByProductObj refers selected products details
     * @param dataLayer refers datalayer variables for virtualPageview
     */ 
    setNavigationTag (dataLayerObj, selectedByProductObj, dataLayer) {
        /**
         * When open menu, privacy policy, access management, contact us, logout and language change event click
         * But not browser back button click event
         */ 
        if (!dataLayerObj['stepNumber'] && !dataLayerObj['pageName'] && !dataLayerObj['currentStepper']) {
            if (!dataLayerObj['stepperObject']) {
                dataLayerObj['stepperObject'] = this.stepperObject;
            }
            
            if (dataLayerObj['stepperObject'].length) {
                let stepperObj = dataLayerObj['stepperObject'][0];

                dataLayerObj['currentStepper'] = stepperObj.stepperCurrentCount === undefined ? stepperObj.stepperProgressCount : stepperObj.stepperCurrentCount;

                if ((dataLayerObj['landingStepper'] === undefined || dataLayerObj['event'] === 'change_language' ||
                    dataLayerObj['eventLabel'] === 'Logo') && stepperObj.stepperCurrentCount === undefined) {
                    ++dataLayerObj['currentStepper'];
                }
            } else {
                dataLayerObj['currentStepper'] = 0;
            }
        }

        /**
         * Update PageName and StepNumber
         */ 
        dataLayer = this.updatePageNameStepNumber (dataLayerObj, dataLayer, dataLayerObj['currentStepper']);

        /**
         * when user using menu interaction to navigate to back steps
         */ 
        if (dataLayerObj['currentStepper'] >= 0 && selectedByProductObj.length) {
            selectedByProductObj.splice(dataLayerObj['currentStepper'], selectedByProductObj.length);
        }
        
        /**
         * Update PageURL and selected products details
         */ 
        dataLayer = this.setProductsDetails(selectedByProductObj, dataLayer);
        
        /**
         * would collect the URL of the page the visitor is on or interacting with the website on every 
         * datalayer.push? Please set it in a way that it will not get any external URL parameters
         */ 
        dataLayer['pageURL'] = window.location.origin + window.location.pathname;

        /**
         * Contains the event name the moment of a triggered interaction. 
         * submittal_success: when the user succesfully clicks on "Create Submittal". 
         * navigation: when the user clicks either on the menu interactions or the blue link sending them 
         * to the Firestop submittals page. 
         * change_language: when the user successfully switches from en to fr (or vice versa) on the canadian site.
         */ 
        dataLayer['event'] = dataLayerObj['event'];

        /**
         * Includes the value that the event category variable inside GA should get, when an event tag is triggered. 
         */ 
        dataLayer['eventCategory'] = dataLayerObj['eventCategory'];

        /**
         * Includes the value that the event action variable inside GA should get, when an event tag is triggered. 
         * Create: when the user succesfully clicks on "Create Submittal". Navigation: when the user clicks either 
         * on the menu interactions or the blue link sending them to the Firestop submittals page. 
         * Change Language: when the user successfully switches from en to fr (or vice versa) on the canadian site.
         */ 
        dataLayer['eventAction'] = dataLayerObj['eventAction'];

        /**
         * Includes the value that the event label variable inside GA should get, when an event tag is triggered. 
         * Success:  when the user succesfully clicks on "Create Submittal". Open Menu: when the user clicks to 
         * open the small menu on top right. Access Agreement: When the user clicks on "Access Agreement" inside 
         * the menu. Privacy Policy: When the user clicks on "Privacy Policy" inside the menu. Contact Us: When 
         * the user clicks on "Contact Us" inside the menu. Firestop: When the user clicks on the blue link 
         * sending them to the Firestop submittals page. Logo: when the used clicks on the logo of the website 
         * and goes back to the homepage. Logout: when the user clicks on logour inside the menu. en-CA: When 
         * the user switches from french to english on the canadian site. fr-CA: When the user switches from 
         * english to french on the canadian site.
         */ 
        if (dataLayerObj['event'] === 'change_language') {
            dataLayer['eventLabel'] = dataLayer['pageLanguage'];
        }
        
        if (dataLayerObj['eventLabel'] === 'navigation') {
            let landingSteper = dataLayerObj['landingStepper'];

            if (landingSteper > 1 && dataLayerObj['stepperObject']) {
                if (dataLayerObj['stepperObject'].filter(obj => { return obj.title === 'STEPPER.SECTIONS' }).length == 0) {
                    ++landingSteper;
                }
            }

            dataLayer['eventLabel'] = 'Top Menu|' + this.headerArr[landingSteper];
        } else {
            dataLayer['eventLabel'] = dataLayerObj['eventLabel'];    
        }

        window['dataLayer'].push(dataLayer);
    }

    /**
     * @method setPageViewTag uses to set the information of the page that we are landing on (pageName/URL/StepNumber) 
     * and it gives the data of our pageviews.
     * @param dataLayerObj refers input from different components
     * @param selectedByProductObj refers selected products details
     * @param dataLayer refers datalayer variables for virtualPageview
     */ 
    setPageViewTag (dataLayerObj, selectedByProductObj, dataLayer) {
        dataLayer['event'] = 'virtualPageview';

        /**
         * Update PageName and StepNumber
         */ 
        dataLayer = this.updatePageNameStepNumber (dataLayerObj, dataLayer, dataLayerObj['landingStepper']);

        if (dataLayerObj['landingStepper'] >= 0 && selectedByProductObj.length) {
            selectedByProductObj.splice(dataLayerObj['landingStepper'], selectedByProductObj.length);
        }

        /**
         * Update PageURL and selected products details
         */ 
        dataLayer = this.setProductsDetails(selectedByProductObj, dataLayer);

        window['dataLayer'].push(dataLayer);
    }
}
