import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { Location } from '@angular/common';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';

@Component({
    selector: 'hilti-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnChanges {

    public progressCompleteProgress: number = 0;
    public progressInompleteProgress: number = 100;

    /**
     * stepLabel is for mobile view to display the step status.
     */
    public stepLabel: string = '0 of 0';

    @Input() stepperObject: any = [];
    @Output() clickedStepObject = new EventEmitter();

    constructor(
        public config: LanguageConfig,
        private location: Location,
        private tagService: GoogleTagManagerService
    ) {
        /**
         * handle stepper if the user clicks on browser back button
         */
        location.subscribe(loc => {
            let tempStep: any = [];
            tempStep = this.stepperObject.filter(obj => { return obj.routeUrl == loc.url });
            if (tempStep.length > 0) {
                this.stepClicked(tempStep[0], true);
            }
        })
    }

    /**
     * @method ngOnChanges use to trigger nextstep function when stepper getting changed
     */
    ngOnChanges(): void {
        if (this.stepperObject.length > 0) {
            this.nextStep();
        }
    }

    /**
     * @method nextStep use to Here 'stepperCurrentCount' represents the selected step and will be in red. 
     * 'stepperProgressCount' represents the total progress of the stepper. Till 'stepperProgressCount' the steps 
     * will be enabled for the user to click back and forth. Beyond 'stepperProgressCount' the steps will be 
     * disabled and will be in light grey color. The attribute 'stepValue' is the value of the particular step. 
     * So, if 'stepperCurrentCount' == 'stepValue' that particular step will be selected and will be in red. Each 
     * step will be associated with a routeUrl and on click of that step the respective component will be routed.
     */
    nextStep() {
        let tempCount: any = {};
        /* stepperCurrentCount will be available only if user clicks on a passed step. */
        if (this.stepperObject[0] && this.stepperObject[0].stepperCurrentCount) {
            tempCount = Object.assign({}, this.stepperObject[0]);
            if (this.stepperObject[0] && this.stepperObject[0].stepperCurrentCount > this.stepperObject[0].stepperProgressCount) {
                this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperCurrentCount: tempCount.stepperProgressCount }));
            }
            tempCount = Object.assign({}, this.stepperObject[0]);
        } else {
            /* if there is no stepperCurrentCount, stepperProgressCount will be increased. */
            /* We dont want 'stepperProgressCount' to go beyond total step lenth. If That heppens anyway we will keep the last step highlighted  */
            if (this.stepperObject[0] && this.stepperObject.length <= this.stepperObject[0].stepperProgressCount) {
                this.stepperObject = this.stepperObject.map(v => ({
                    ...v,
                    stepperProgressCount: this.stepperObject.length - 1
                }));
                tempCount = Object.assign({}, this.stepperObject[0]);
            } else {
                if (this.stepperObject[0]) {
                    /* This will increse the stepper Progress and will move to next step */
                    this.stepperObject = this.stepperObject.map(v => ({
                        ...v,
                        stepperProgressCount: this.stepperObject[0].stepperProgressCount + 1
                    }));
                    tempCount = Object.assign({}, this.stepperObject[0]);
                }
            }

        }
        if (tempCount) {
            /* This is to the progress bar below the stepper. */
            this.progressCompleteProgress = (100 / this.stepperObject.length) * (tempCount.stepperProgressCount + 1);
            this.progressInompleteProgress = 100 - this.progressCompleteProgress;
        }
        /* We dont want 'stepperProgressCount' to go beyond total step lenth. If That heppens anyway we will keep the last step highlighted  */
        if (this.stepperObject[0] && this.stepperObject.length <= this.stepperObject[0].stepperProgressCount) {
            this.stepperObject = this.stepperObject.map(v => ({
                ...v,
                stepperProgressCount: this.stepperObject.length - 1
            }));
        }
        if (tempCount) {
            /* This is to display step numbers on mobile view. */
            this.stepLabel = (tempCount.stepperCurrentCount ? tempCount.stepperCurrentCount + 1 : tempCount.stepperProgressCount + 1) + ' of ' + this.stepperObject.length;
        }
    }

    /**
     * @method goStepBack use to mobile view click event.
     */
    goStepBack() {
        let tempCount: any = {};
        tempCount = Object.assign({}, this.stepperObject[0]);
        if (tempCount) {
            this.stepClicked(this.stepperObject[(tempCount.stepperCurrentCount ? tempCount.stepperCurrentCount - 1 : tempCount.stepperProgressCount - 1)])
        }
    }

    /**
     * @method updateMenuInteractionTags use to set datalayer details
     * @param stepValue refer stepper value
     * @param browserBackBtnClick refer browser back button clicked or not
     */
    updateMenuInteractionTags(stepValue, browserBackBtnClick) {
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'navigation',
            'landingStepper': stepValue,
            'stepperObject': this.stepperObject,
            'browserBackBtnClick': browserBackBtnClick
        });
    }

    /**
     * @method stepClicked use to triggered if user clicks on a passed step. 
     * @param e refers which stepper click event
     * @param browserBackBtnClick refer either browser back button click event or not
     */
    stepClicked(e, browserBackBtnClick: boolean = false) {
        /**
         * event is triggered on a condition that the clicked step is already passed. User is not allowed to click 
         * on a disable step.
         */
        if (e && (e.stepperProgressCount >= e.stepValue || browserBackBtnClick)) {
            /**
             * When user navigate via menu interaction only
             */
            if (!browserBackBtnClick) {
                this.updateMenuInteractionTags(e.stepValue, browserBackBtnClick)
            }

            this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperCurrentCount: e.stepValue }));
            this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperProgressCount: e.stepValue }));

            this.clickedStepObject.emit(e);
        }
    }
}
