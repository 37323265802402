import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmittalsProductDocumentListRoutingModule } from './submittals-product-document-list-routing.module';
import { SubmittalsProductDocumentListComponent } from './submittals-product-document-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DropdownModule } from 'hilti-ui-library';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubmittalsProductDocumentListComponent],
  imports: [
    CommonModule,
    SubmittalsProductDocumentListRoutingModule,
    SharedModule, DropdownModule, TranslateModule
  ]
})
export class SubmittalsProductDocumentListModule { }
