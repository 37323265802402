import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsProductCategoriesComponent } from './submittals-product-categories.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';
import { SubmittalsProductCategoriesResolver } from './submittals-product-categories.resolver.service';

const productCatRoutes: Routes = [
  {
    path: '', component: SubmittalsProductCategoriesComponent,
    canDeactivate: [NavigationGuard],
    resolve: { productCategories: SubmittalsProductCategoriesResolver },
    children: [
      {
        path: 'product-category/:pid',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductsModule)
      },
      {
        path: 'product-category-section/:pcsid',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductSectionsModule)
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(productCatRoutes)],
  exports: [RouterModule],
})
export class SubmittalsProductCategoriesRoutingModule { }
