import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MenuListItem } from 'hilti-ui-library/lib/list-item/model/menu-item-list.model';
import { TranslateService } from '@ngx-translate/core';
import { ListItemEvent } from 'hilti-ui-library/lib/list-item/model/list-item-event.model';
import { DataService } from 'src/app/shared/services/data.service';
import { CartService } from '../../services/cart.service';

@Component({
  selector: 'app-submittals-cart-delivery',
  templateUrl: './submittals-cart-delivery.component.html',
  styleUrls: ['./submittals-cart-delivery.component.scss']
})

export class SubmittalsCartDeliveryComponent implements OnInit {
  deliveryMethodList: MenuListItem[] = [];
  coverPageList: MenuListItem[] = [];
  showEmailField = false;
  uploadSuccess: boolean;
  uploadOnlyPdfErrorShow = false;
  selectedCoverPage = 'HILTI_COVER_PAGE';
  selectedDelierymethod = 'VIEW_ONLINE_DOWNLOAD';
  private coverPageIdentifier: File;
  private fileToUpload: File = null;
  @Input() optionsForm: FormGroup;
  @Output() customCoverAndEmailSelectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private cartService: CartService
  ) { }

  ngOnInit() {
    this.deliveryMethodList.push({ text: this.translate.instant('OPTIONS.DELIVERY_METHOD.VIEW_ONLINE_DOWNLOAD'), value: 'VIEW_ONLINE_DOWNLOAD' })
    this.deliveryMethodList.push({ text: this.translate.instant('OPTIONS.DELIVERY_METHOD.EMAIL_SUBMITTAL.TITLE'), value: 'EMAIL_SUBMITTAL' })
    this.coverPageList.push({ text: this.translate.instant('OPTIONS.COVER_PAGE.HILTI_COVER_PAGE'), value: 'HILTI_COVER_PAGE' })
    this.coverPageList.push({ text: this.translate.instant('OPTIONS.COVER_PAGE.CUSTOM_COVER_PAGE'), value: 'CUSTOM_COVER_PAGE' })
    this.coverPageList.push({ text: this.translate.instant('OPTIONS.COVER_PAGE.NO_COVER_PAGE'), value: 'NO_COVER_PAGE' })
  }

  updateDeliveryMethod(event: ListItemEvent): void {
    this.selectedDelierymethod = event.listItem.value == 'EMAIL_SUBMITTAL' ? 'emailSub' : 'viewOnline'
    this.optionsForm.patchValue({ 'deliverMethodRadioBtn': this.selectedDelierymethod });
    if (event.listItem.value == 'EMAIL_SUBMITTAL') {
      this.optionsForm.addControl('userEmailInput',
        new FormControl('', [
          Validators.required,
          this.cartService.emailCustomValidator
        ]))
      this.showEmailField = true
    }
    else {
      this.showEmailField = false;
      this.optionsForm.removeControl('userEmailInput')
    }
    this.customCoverAndEmailSelectedChange.emit(this.checkCustomCoverAndEmailSelected());
  }

  checkCustomCoverAndEmailSelected(): boolean{
    return this.selectedCoverPage === 'customCoverPage' && this.showEmailField;
  }

  updateCoverPage(event: ListItemEvent): void {
    this.uploadSuccess = undefined;
    this.uploadOnlyPdfErrorShow = false;
    this.selectedCoverPage = event.listItem.value === 'CUSTOM_COVER_PAGE' ? 'customCoverPage' : event.listItem.value === 'HILTI_COVER_PAGE' ? 'hiltiCoverPage' : 'noCoverPage';
    this.optionsForm.patchValue({ 'coverPageRadioBtn': this.selectedCoverPage });
    if (event.listItem.value === 'CUSTOM_COVER_PAGE') {
      this.optionsForm.addControl('customCoverInput',
        new FormControl('', [
          Validators.required
        ]));
    } else {
      this.optionsForm.removeControl('customCoverInput')
    }
    this.customCoverAndEmailSelectedChange.emit(this.checkCustomCoverAndEmailSelected());
  }

  /* istanbul ignore next */
  uploadFile(files: FileList): void {
    this.uploadOnlyPdfErrorShow = false;
    this.uploadSuccess = undefined;

    if (typeof files.item == 'function') {
      this.fileToUpload = files.item(0);
      /* only PDF less than 5MB is allowed to be uploaded */
      if (this.fileToUpload.type == 'application/pdf' && this.fileToUpload.size < 5000001) {
        this.coverPageIdentifier = this.fileToUpload;
        this.dataService.changeCustomCoverPageFile(this.coverPageIdentifier);
      } else {
        /* cleared the file from the input if any condition is failed */
        this.optionsForm.patchValue({ 'customCoverInput': '' });
        this.uploadOnlyPdfErrorShow = true;
        this.uploadSuccess = undefined;
      }
    }
  }
  updateEmail(event: string) {
    this.optionsForm.patchValue({ 'userEmailInput': event });
  }
}
