import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HiltiTranslateService } from '../../services/hilti-translate.service';
import { LanguageConfig } from '../../models/language-config.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { HiltiCustomConfirmModalComponent } from '../hilti-custom-confirm-modal/hilti-custom-confirm-modal.component';
import { LogoutService } from '../../services/logout.service';
import { GoogleTagManagerService } from '../../services/google-tag-manager.service';
import { DataService } from 'src/app/shared/services/data.service';
import { delay } from 'rxjs/operators';
import { HiltiCustomModalComponent } from '../hilti-custom-modal/hilti-custom-modal.component';
import { Cart } from 'src/app/modules/submittals-cart/models/cart.model';
import { SessionStorageService } from '../../services/session-storage.service';
import { CartService } from 'src/app/modules/submittals-cart/services/cart.service';


@Component({
    selector: 'hilti-custom-header',
    templateUrl: './hilti-custom-header.component.html',
    styleUrls: ['./hilti-custom-header.component.scss']
})
export class HiltiCustomHeaderComponent implements OnInit {

    public stepperObject: Array<any> = [];
    cart: Cart;

    constructor(
        private router: Router,
        private logoutService: LogoutService,
        private translateService: HiltiTranslateService,
        public config: LanguageConfig,
        private modalService: BsModalService,
        public modalRef: BsModalRef,
        private tagService: GoogleTagManagerService,
        private dataService: DataService,
        private cartService: CartService
    ) {
        this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = Object.assign([], c);
            });
     }

    @Input() userName: string = ''
    @Input() headerTitle: string = ''
    @Input() langChangeRequired: boolean = false;
    @Input() logoutRequired: boolean = true;
    @Input() customHeaderDropDown: Array<any> = [];
    @Output('changeLanguageLink') changeLanguageLink = new EventEmitter();

    ngOnInit() {
        this.cartService.setCartObject();
        this.cart = this.cartService.cart;
    }

    /**
     * @method changeLang use to update langage when user change language
     * @param lang refers which language user need to change
     */
    changeLang(lang) {
        if (this.config.defaultLang.value != lang) {
            if (location.pathname === '/by-products' && this.cart.cartTotalProducts === 0) {
                if (this.config.availableLangs) {
                    this.config.defaultLang = this.config.availableLangs.find((data: any) => {
                        return data.value.toLowerCase() === lang.toLocaleLowerCase();
                    })
                }
                this.translateService.use(this.config.defaultLang).then(res => {
                    /**
                     * not expressed in a service because it is being used in a module 'runGuardsAndResolvers' function
                     */
                    sessionStorage.setItem('changeLanguage', 'yes');
                    
                    this.setDataLayer (true);
                    
                    /**
                     * routing to dummy will redirect back to product. Thtas how we are getting by product with chanaged 
                     * language without refreshing
                     */ 
                    this.router.navigate(['/dummy']);

                });
            } else {
                const initialState = {
                    imgURL: '../assets/images/alert_info.svg',
                    title: 'CONFIRM_MODAL.LANGUAGE_CHANGE_MODAL.MODAL_CONTENT',
                    confirmbuttonTitle: 'CONFIRM_MODAL.LANGUAGE_CHANGE_MODAL.MODAL_CONFIRM_BUTTON',
                    cancelbuttonTitle: 'CONFIRM_MODAL.LANGUAGE_CHANGE_MODAL.MODAL_CANCEL_BUTTON',

                };

                this.modalRef = this.modalService.show(HiltiCustomConfirmModalComponent, { class: ' modal-dialog-centered', initialState });
                this.modalRef.content.onClose.subscribe(yes => {
                    if (yes) {
                        if (this.config.availableLangs) {
                            this.config.defaultLang = this.config.availableLangs.find((data: any) => {
                                return data.value.toLowerCase() === lang.toLocaleLowerCase();
                            })
                        }
                        this.translateService.use(this.config.defaultLang).then(res => {
                            /**
                             * not expressed in a service because it is being used in a module 'runGuardsAndResolvers' function
                             */
                            sessionStorage.setItem('changeLanguage', 'yes');

                            this.setDataLayer ();
                            
                            this.navigateToHome();
                            this.cart.clearCart();
                        });
                    }
                });
            }
        }
    }

    /**
     * @method resetStepper use to reset stepper when user navigate to home page
     */ 
    resetStepper () {
        if (this.stepperObject.length) {
            this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperCurrentCount: 0, stepperProgressCount: 0 }));
            this.dataService.changeStepperObjectGlobal(this.stepperObject);
        }
    }

    /**
     * @method setDataLayer use to bind datalayer variables to window.dalayer array to track user change events
     * @param isHome refer where user click change language button
     */ 
    setDataLayer (isHome: boolean = false) {
        let reqDatalayer = {
            'event': 'change_language',
            'eventCategory': 'Submittals',
            'eventAction': 'Change Language',
            'landingStepper': 0,
            'stepperObject': this.stepperObject
        };

        this.resetStepper ();

        this.tagService.setGMTDataLayer(reqDatalayer);

        this.changeLanguageLink.emit();
    }

    /**
     * @method Logout use to user logout session
     */ 
    Logout() {
        
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'Logout'
        });

        this.logoutService.logout();
    }

    /**
     * @method navigateToHome use to user click logo icon to navigate to home page
     * @param isPage refers which page to navigate
     */
    navigateToHome(isPage = '') {
        if (isPage === 'logo') {
            this.tagService.setGMTDataLayer({
                'event': 'navigation',
                'eventCategory': 'Submittals',
                'eventAction': 'Navigation',
                'eventLabel': 'Logo',
                'landingStepper': 0
            });
        }

        this.resetStepper ();

        this.router.navigate(['/by-products']);
    }

    /**
     * @method openMenuModaluse to bind datalayer variables when user open datalayer
     */
    openMenuModal () {
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'Open Menu'
        });
    }

    /**
     * @method navigateToOtherPages use to navigate to other page in menu
     * @param isMenu refers which page user selected to navigate
     */
    navigateToOtherPages (isMenu) {
        let eventName = isMenu === 'LINKS.ACCESS_AGREEMENT_LINK' ? 'Access Agreement' : (isMenu === 'LINKS.PRIVACY_POLICY_LINK' ? 'Privacy Policy' : 'Contact Us');

        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': eventName
        });
    }
}
