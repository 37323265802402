import {CountryConfig} from "../models/country-config.model";

const countries = [
    {
        code: "AF",
        dialCode: "+93",
        name: "Afghanistan",
        display: false
    },
    {
        code: "AL",
        dialCode: "+355",
        name: "Albania",
        display: false
    },
    {
        code: "DZ",
        dialCode: "+213",
        name: "Algeria",
        display: false
    },
    {
        code: "AS",
        dialCode: "+1 684",
        name: "AmericanSamoa",
        display: false
    },
    {
        code: "AD",
        dialCode: "+376",
        name: "Andorra",
        display: false
    },
    {
        code: "AO",
        dialCode: "+244",
        name: "Angola",
        display: false
    },
    {
        code: "AI",
        dialCode: "+1 264",
        name: "Anguilla",
        display: false
    },
    {
        code: "AG",
        dialCode: "+1268",
        name: "Antigua and Barbuda",
        display: false
    },
    {
        code: "AR",
        dialCode: "+54",
        name: "Argentina",
        display: false
    },
    {
        code: "AM",
        dialCode: "+374",
        name: "Armenia",
        display: false
    },
    {
        code: "AW",
        dialCode: "+297",
        name: "Aruba",
        display: false
    },
    {
        code: "AU",
        dialCode: "+61",
        name: "Australia",
        display: false
    },
    {
        code: "AT",
        dialCode: "+43",
        name: "Austria",
        display: true
    },
    {
        code: "AZ",
        dialCode: "+994",
        name: "Azerbaijan",
        display: false
    },
    {
        code: "BS",
        dialCode: "+1 242",
        name: "Bahamas",
        display: false
    },
    {
        code: "BH",
        dialCode: "+973",
        name: "Bahrain",
        display: false
    },
    {
        code: "BD",
        dialCode: "+880",
        name: "Bangladesh",
        display: false
    },
    {
        code: "BB",
        dialCode: "+1 246",
        name: "Barbados",
        display: false
    },
    {
        code: "BY",
        dialCode: "+375",
        name: "Belarus",
        display: false
    },
    {
        code: "BE",
        dialCode: "+32",
        name: "Belgium",
        display: true
    },
    {
        code: "BZ",
        dialCode: "+501",
        name: "Belize",
        display: false
    },
    {
        code: "BJ",
        dialCode: "+229",
        name: "Benin",
        display: false
    },
    {
        code: "BM",
        dialCode: "+1 441",
        name: "Bermuda",
        display: false
    },
    {
        code: "BT",
        dialCode: "+975",
        name: "Bhutan",
        display: false
    },
    {
        code: "BA",
        dialCode: "+387",
        name: "Bosnia and Herzegovina",
        display: false
    },
    {
        code: "BW",
        dialCode: "+267",
        name: "Botswana",
        display: false
    },
    {
        code: "BR",
        dialCode: "+55",
        name: "Brazil",
        display: false
    },
    {
        code: "IO",
        dialCode: "+246",
        name: "British Indian Ocean Territory",
        display: false
    },
    {
        code: "BG",
        dialCode: "+359",
        name: "Bulgaria",
        display: false
    },
    {
        code: "BF",
        dialCode: "+226",
        name: "Burkina Faso",
        display: false
    },
    {
        code: "BI",
        dialCode: "+257",
        name: "Burundi",
        display: false
    },
    {
        code: "KH",
        dialCode: "+855",
        name: "Cambodia",
        display: false
    },
    {
        code: "CM",
        dialCode: "+237",
        name: "Cameroon",
        display: false
    },
    {
        code: "CA",
        dialCode: "+1",
        name: "Canada",
        display: true
    },
    {
        code: "CV",
        dialCode: "+238",
        name: "Cape Verde",
        display: false
    },
    {
        code: "KY",
        dialCode: "+ 345",
        name: "Cayman Islands",
        display: false
    },
    {
        code: "CF",
        dialCode: "+236",
        name: "Central African Republic",
        display: false
    },
    {
        code: "TD",
        dialCode: "+235",
        name: "Chad",
        display: false
    },
    {
        code: "CL",
        dialCode: "+56",
        name: "Chile",
        display: false
    },
    {
        code: "CN",
        dialCode: "+86",
        name: "China",
        display: false
    },
    {
        code: "CX",
        dialCode: "+61",
        name: "Christmas Island",
        display: false
    },
    {
        code: "CO",
        dialCode: "+57",
        name: "Colombia",
        display: false
    },
    {
        code: "KM",
        dialCode: "+269",
        name: "Comoros",
        display: false
    },
    {
        code: "CG",
        dialCode: "+242",
        name: "Congo",
        display: false
    },
    {
        code: "CK",
        dialCode: "+682",
        name: "Cook Islands",
        display: false
    },
    {
        code: "CR",
        dialCode: "+506",
        name: "Costa Rica",
        display: false
    },
    {
        code: "HR",
        dialCode: "+385",
        name: "Croatia",
        display: false
    },
    {
        code: "CU",
        dialCode: "+53",
        name: "Cuba",
        display: false
    },
    {
        code: "CY",
        dialCode: "+537",
        name: "Cyprus",
        display: false
    },
    {
        code: "CZ",
        dialCode: "+420",
        name: "Czech Republic",
        display: false
    },
    {
        code: "DK",
        dialCode: "+45",
        name: "Denmark",
        display: false
    },
    {
        code: "DJ",
        dialCode: "+253",
        name: "Djibouti",
        display: false
    },
    {
        code: "DM",
        dialCode: "+1 767",
        name: "Dominica",
        display: false
    },
    {
        code: "DO",
        dialCode: "+1 849",
        name: "Dominican Republic",
        display: false
    },
    {
        code: "EC",
        dialCode: "+593",
        name: "Ecuador",
        display: false
    },
    {
        code: "EG",
        dialCode: "+20",
        name: "Egypt",
        display: false
    },
    {
        code: "SV",
        dialCode: "+503",
        name: "El Salvador",
        display: false
    },
    {
        code: "GQ",
        dialCode: "+240",
        name: "Equatorial Guinea",
        display: false
    },
    {
        code: "ER",
        dialCode: "+291",
        name: "Eritrea",
        display: false
    },
    {
        code: "EE",
        dialCode: "+372",
        name: "Estonia",
        display: false
    },
    {
        code: "ET",
        dialCode: "+251",
        name: "Ethiopia",
        display: false
    },
    {
        code: "FO",
        dialCode: "+298",
        name: "Faroe Islands",
        display: false
    },
    {
        code: "FJ",
        dialCode: "+679",
        name: "Fiji",
        display: false
    },
    {
        code: "FI",
        dialCode: "+358",
        name: "Finland",
        display: false
    },
    {
        code: "FR",
        dialCode: "+33",
        name: "France",
        display: true
    },
    {
        code: "GF",
        dialCode: "+594",
        name: "French Guiana",
        display: false
    },
    {
        code: "PF",
        dialCode: "+689",
        name: "French Polynesia",
        display: false
    },
    {
        code: "GA",
        dialCode: "+241",
        name: "Gabon",
        display: false
    },
    {
        code: "GM",
        dialCode: "+220",
        name: "Gambia",
        display: false
    },
    {
        code: "GE",
        dialCode: "+995",
        name: "Georgia",
        display: false
    },
    {
        code: "DE",
        dialCode: "+49",
        name: "Germany",
        display: false
    },
    {
        code: "GH",
        dialCode: "+233",
        name: "Ghana",
        display: false
    },
    {
        code: "GI",
        dialCode: "+350",
        name: "Gibraltar",
        display: false
    },
    {
        code: "GR",
        dialCode: "+30",
        name: "Greece",
        display: false
    },
    {
        code: "GL",
        dialCode: "+299",
        name: "Greenland",
        display: false
    },
    {
        code: "GD",
        dialCode: "+1 473",
        name: "Grenada",
        display: false
    },
    {
        code: "GP",
        dialCode: "+590",
        name: "Guadeloupe",
        display: false
    },
    {
        code: "GU",
        dialCode: "+1 671",
        name: "Guam",
        display: false
    },
    {
        code: "GT",
        dialCode: "+502",
        name: "Guatemala",
        display: false
    },
    {
        code: "GN",
        dialCode: "+224",
        name: "Guinea",
        display: false
    },
    {
        code: "GW",
        dialCode: "+245",
        name: "Guinea-Bissau",
        display: false
    },
    {
        code: "GY",
        dialCode: "+595",
        name: "Guyana",
        display: false
    },
    {
        code: "HT",
        dialCode: "+509",
        name: "Haiti",
        display: false
    },
    {
        code: "HN",
        dialCode: "+504",
        name: "Honduras",
        display: false
    },
    {
        code: "HU",
        dialCode: "+36",
        name: "Hungary",
        display: false
    },
    {
        code: "IS",
        dialCode: "+354",
        name: "Iceland",
        display: false
    },
    {
        code: "IN",
        dialCode: "+91",
        name: "India",
        display: false
    },
    {
        code: "ID",
        dialCode: "+62",
        name: "Indonesia",
        display: false
    },
    {
        code: "IQ",
        dialCode: "+964",
        name: "Iraq",
        display: false
    },
    {
        code: "IE",
        dialCode: "+353",
        name: "Ireland",
        display: false
    },
    {
        code: "IL",
        dialCode: "+972",
        name: "Israel",
        display: false
    },
    {
        code: "IT",
        dialCode: "+39",
        name: "Italy",
        display: true
    },
    {
        code: "JM",
        dialCode: "+1 876",
        name: "Jamaica",
        display: false
    },
    {
        code: "JP",
        dialCode: "+81",
        name: "Japan",
        display: false
    },
    {
        code: "JO",
        dialCode: "+962",
        name: "Jordan",
        display: false
    },
    {
        code: "KZ",
        dialCode: "+7 7",
        name: "Kazakhstan",
        display: false
    },
    {
        code: "KE",
        dialCode: "+254",
        name: "Kenya",
        display: false
    },
    {
        code: "KI",
        dialCode: "+686",
        name: "Kiribati",
        display: false
    },
    {
        code: "KW",
        dialCode: "+965",
        name: "Kuwait",
        display: false
    },
    {
        code: "KG",
        dialCode: "+996",
        name: "Kyrgyzstan",
        display: false
    },
    {
        code: "LV",
        dialCode: "+371",
        name: "Latvia",
        display: false
    },
    {
        code: "LB",
        dialCode: "+961",
        name: "Lebanon",
        display: false
    },
    {
        code: "LS",
        dialCode: "+266",
        name: "Lesotho",
        display: false
    },
    {
        code: "LR",
        dialCode: "+231",
        name: "Liberia",
        display: false
    },
    {
        code: "LI",
        dialCode: "+423",
        name: "Liechtenstein",
        display: true
    },
    {
        code: "LT",
        dialCode: "+370",
        name: "Lithuania",
        display: false
    },
    {
        code: "LU",
        dialCode: "+352",
        name: "Luxembourg",
        display: false
    },
    {
        code: "MG",
        dialCode: "+261",
        name: "Madagascar",
        display: false
    },
    {
        code: "MW",
        dialCode: "+265",
        name: "Malawi",
        display: false
    },
    {
        code: "MY",
        dialCode: "+60",
        name: "Malaysia",
        display: false
    },
    {
        code: "MV",
        dialCode: "+960",
        name: "Maldives",
        display: false
    },
    {
        code: "ML",
        dialCode: "+223",
        name: "Mali",
        display: false
    },
    {
        code: "MT",
        dialCode: "+356",
        name: "Malta",
        display: false
    },
    {
        code: "MH",
        dialCode: "+692",
        name: "Marshall Islands",
        display: false
    },
    {
        code: "MQ",
        dialCode: "+596",
        name: "Martinique",
        display: false
    },
    {
        code: "MR",
        dialCode: "+222",
        name: "Mauritania",
        display: false
    },
    {
        code: "MU",
        dialCode: "+230",
        name: "Mauritius",
        display: false
    },
    {
        code: "YT",
        dialCode: "+262",
        name: "Mayotte",
        display: false
    },
    {
        code: "MX",
        dialCode: "+52",
        name: "Mexico",
        display: false
    },
    {
        code: "MC",
        dialCode: "+377",
        name: "Monaco",
        display: false
    },
    {
        code: "MN",
        dialCode: "+976",
        name: "Mongolia",
        display: false
    },
    {
        code: "ME",
        dialCode: "+382",
        name: "Montenegro",
        display: false
    },
    {
        code: "MS",
        dialCode: "+1664",
        name: "Montserrat",
        display: false
    },
    {
        code: "MA",
        dialCode: "+212",
        name: "Morocco",
        display: false
    },
    {
        code: "MM",
        dialCode: "+95",
        name: "Myanmar",
        display: false
    },
    {
        code: "NA",
        dialCode: "+264",
        name: "Namibia",
        display: false
    },
    {
        code: "NR",
        dialCode: "+674",
        name: "Nauru",
        display: false
    },
    {
        code: "NP",
        dialCode: "+977",
        name: "Nepal",
        display: false
    },
    {
        code: "NL",
        dialCode: "+31",
        name: "Netherlands",
        display: true
    },
    {
        code: "AN",
        dialCode: "+599",
        name: "Netherlands Antilles",
        display: false
    },
    {
        code: "NC",
        dialCode: "+687",
        name: "New Caledonia",
        display: false
    },
    {
        code: "NZ",
        dialCode: "+64",
        name: "New Zealand",
        display: false
    },
    {
        code: "NI",
        dialCode: "+505",
        name: "Nicaragua",
        display: false
    },
    {
        code: "NE",
        dialCode: "+227",
        name: "Niger",
        display: false
    },
    {
        code: "NG",
        dialCode: "+234",
        name: "Nigeria",
        display: false
    },
    {
        code: "NU",
        dialCode: "+683",
        name: "Niue",
        display: false
    },
    {
        code: "NF",
        dialCode: "+672",
        name: "Norfolk Island",
        display: false
    },
    {
        code: "MP",
        dialCode: "+1 670",
        name: "Northern Mariana Islands",
        display: false
    },
    {
        code: "NO",
        dialCode: "+47",
        name: "Norway",
        display: false
    },
    {
        code: "OM",
        dialCode: "+968",
        name: "Oman",
        display: false
    },
    {
        code: "PK",
        dialCode: "+92",
        name: "Pakistan",
        display: false
    },
    {
        code: "PW",
        dialCode: "+680",
        name: "Palau",
        display: false
    },
    {
        code: "PA",
        dialCode: "+507",
        name: "Panama",
        display: false
    },
    {
        code: "PG",
        dialCode: "+675",
        name: "Papua New Guinea",
        display: false
    },
    {
        code: "PY",
        dialCode: "+595",
        name: "Paraguay",
        display: false
    },
    {
        code: "PE",
        dialCode: "+51",
        name: "Peru",
        display: false
    },
    {
        code: "PH",
        dialCode: "+63",
        name: "Philippines",
        display: false
    },
    {
        code: "PL",
        dialCode: "+48",
        name: "Poland",
        display: true
    },
    {
        code: "PT",
        dialCode: "+351",
        name: "Portugal",
        display: true
    },
    {
        code: "PR",
        dialCode: "+1 939",
        name: "Puerto Rico",
        display: false
    },
    {
        code: "QA",
        dialCode: "+974",
        name: "Qatar",
        display: false
    },
    {
        code: "RO",
        dialCode: "+40",
        name: "Romania",
        display: false
    },
    {
        code: "RW",
        dialCode: "+250",
        name: "Rwanda",
        display: false
    },
    {
        code: "WS",
        dialCode: "+685",
        name: "Samoa",
        display: false
    },
    {
        code: "SM",
        dialCode: "+378",
        name: "San Marino",
        display: false
    },
    {
        code: "SA",
        dialCode: "+966",
        name: "Saudi Arabia",
        display: false
    },
    {
        code: "SN",
        dialCode: "+221",
        name: "Senegal",
        display: false
    },
    {
        code: "RS",
        dialCode: "+381",
        name: "Serbia",
        display: false
    },
    {
        code: "SC",
        dialCode: "+248",
        name: "Seychelles",
        display: false
    },
    {
        code: "SL",
        dialCode: "+232",
        name: "Sierra Leone",
        display: false
    },
    {
        code: "SG",
        dialCode: "+65",
        name: "Singapore",
        display: false
    },
    {
        code: "SK",
        dialCode: "+421",
        name: "Slovakia",
        display: false
    },
    {
        code: "SI",
        dialCode: "+386",
        name: "Slovenia",
        display: false
    },
    {
        code: "SB",
        dialCode: "+677",
        name: "Solomon Islands",
        display: false
    },
    {
        code: "ZA",
        dialCode: "+27",
        name: "South Africa",
        display: false
    },
    {
        code: "GS",
        dialCode: "+500",
        name: "South Georgia and the South Sandwich Islands",
        display: false
    },
    {
        code: "ES",
        dialCode: "+34",
        name: "Spain",
        display: true
    },
    {
        code: "LK",
        dialCode: "+94",
        name: "Sri Lanka",
        display: false
    },
    {
        code: "SD",
        dialCode: "+249",
        name: "Sudan",
        display: false
    },
    {
        code: "SR",
        dialCode: "+597",
        name: "Suriname",
        display: false
    },
    {
        code: "SZ",
        dialCode: "+268",
        name: "Swaziland",
        display: false
    },
    {
        code: "SE",
        dialCode: "+46",
        name: "Sweden",
        display: false
    },
    {
        code: "CH",
        dialCode: "+41",
        name: "Switzerland",
        display: true
    },
    {
        code: "TJ",
        dialCode: "+992",
        name: "Tajikistan",
        display: false
    },
    {
        code: "TH",
        dialCode: "+66",
        name: "Thailand",
        display: false
    },
    {
        code: "TG",
        dialCode: "+228",
        name: "Togo",
        display: false
    },
    {
        code: "TK",
        dialCode: "+690",
        name: "Tokelau",
        display: false
    },
    {
        code: "TO",
        dialCode: "+676",
        name: "Tonga",
        display: false
    },
    {
        code: "TT",
        dialCode: "+1 868",
        name: "Trinidad and Tobago",
        display: false
    },
    {
        code: "TN",
        dialCode: "+216",
        name: "Tunisia",
        display: false
    },
    {
        code: "TR",
        dialCode: "+90",
        name: "Turkey",
        display: false
    },
    {
        code: "TM",
        dialCode: "+993",
        name: "Turkmenistan",
        display: false
    },
    {
        code: "TC",
        dialCode: "+1 649",
        name: "Turks and Caicos Islands",
        display: false
    },
    {
        code: "TV",
        dialCode: "+688",
        name: "Tuvalu",
        display: false
    },
    {
        code: "UG",
        dialCode: "+256",
        name: "Uganda",
        display: false
    },
    {
        code: "UA",
        dialCode: "+380",
        name: "Ukraine",
        display: false
    },
    {
        code: "AE",
        dialCode: "+971",
        name: "United Arab Emirates",
        display: false
    },
    {
        code: "GB",
        dialCode: "+44",
        name: "United Kingdom",
        display: true
    },
    {
        code: "US",
        dialCode: "+1",
        name: "United States",
        display: true
    },
    {
        code: "UY",
        dialCode: "+598",
        name: "Uruguay",
        display: false
    },
    {
        code: "UZ",
        dialCode: "+998",
        name: "Uzbekistan",
        display: false
    },
    {
        code: "VU",
        dialCode: "+678",
        name: "Vanuatu",
        display: false
    },
    {
        code: "WF",
        dialCode: "+681",
        name: "Wallis and Futuna",
        display: false
    },
    {
        code: "YE",
        dialCode: "+967",
        name: "Yemen",
        display: false
    },
    {
        code: "ZM",
        dialCode: "+260",
        name: "Zambia",
        display: false
    },
    {
        code: "ZW",
        dialCode: "+263",
        name: "Zimbabwe",
        display: false
    },
    {
        code: "AX",
        dialCode: "",
        name: "land Islands",
        display: false
    },
    {
        code: "AQ",
        dialCode: "",
        name: "Antarctica",
        display: false
    },
    {
        code: "BO",
        dialCode: "+591",
        name: "Bolivia, Plurinational State of",
        display: false
    },
    {
        code: "BN",
        dialCode: "+673",
        name: "Brunei Darussalam",
        display: false
    },
    {
        code: "CC",
        dialCode: "+61",
        name: "Cocos (Keeling) Islands",
        display: false
    },
    {
        code: "CD",
        dialCode: "+243",
        name: "Congo, The Democratic Republic of the",
        display: false
    },
    {
        code: "CI",
        dialCode: "+225",
        name: "Cote d'Ivoire",
        display: false
    },
    {
        code: "FK",
        dialCode: "+500",
        name: "Falkland Islands (Malvinas)",
        display: false
    },
    {
        code: "GG",
        dialCode: "+44",
        name: "Guernsey",
        display: false
    },
    {
        code: "VA",
        dialCode: "+379",
        name: "Holy See (Vatican City State)",
        display: false
    },
    {
        code: "HK",
        dialCode: "+852",
        name: "Hong Kong",
        display: false
    },
    {
        code: "IR",
        dialCode: "+98",
        name: "Iran, Islamic Republic of",
        display: false
    },
    {
        code: "IM",
        dialCode: "+44",
        name: "Isle of Man",
        display: false
    },
    {
        code: "JE",
        dialCode: "+44",
        name: "Jersey",
        display: false
    },
    {
        code: "KP",
        dialCode: "+850",
        name: "Korea, Democratic People's Republic of",
        display: false
    },
    {
        code: "KR",
        dialCode: "+82",
        name: "Korea, Republic of",
        display: false
    },
    {
        code: "LA",
        dialCode: "+856",
        name: "Lao People's Democratic Republic",
        display: false
    },
    {
        code: "LY",
        dialCode: "+218",
        name: "Libyan Arab Jamahiriya",
        display: false
    },
    {
        code: "MO",
        dialCode: "+853",
        name: "Macao",
        display: false
    },
    {
        code: "MK",
        dialCode: "+389",
        name: "Macedonia, The Former Yugoslav Republic of",
        display: false
    },
    {
        code: "FM",
        dialCode: "+691",
        name: "Micronesia, Federated States of",
        display: false
    },
    {
        code: "MD",
        dialCode: "+373",
        name: "Moldova, Republic of",
        display: false
    },
    {
        code: "MZ",
        dialCode: "+258",
        name: "Mozambique",
        display: false
    },
    {
        code: "PS",
        dialCode: "+970",
        name: "Palestinian Territory, Occupied",
        display: false
    },
    {
        code: "PN",
        dialCode: "+872",
        name: "Pitcairn",
        display: false
    },
    {
        code: "RE",
        dialCode: "+262",
        name: "Réunion",
        display: false
    },
    {
        code: "RU",
        dialCode: "+7",
        name: "Russia",
        display: false
    },
    {
        code: "BL",
        dialCode: "+590",
        name: "Saint Barthélemy",
        display: false
    },
    {
        code: "SH",
        dialCode: "+290",
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        display: false
    },
    {
        code: "KN",
        dialCode: "+1 869",
        name: "Saint Kitts and Nevis",
        display: false
    },
    {
        code: "LC",
        dialCode: "+1 758",
        name: "Saint Lucia",
        display: false
    },
    {
        code: "MF",
        dialCode: "+590",
        name: "Saint Martin",
        display: false
    },
    {
        code: "PM",
        dialCode: "+508",
        name: "Saint Pierre and Miquelon",
        display: false
    },
    {
        code: "VC",
        dialCode: "+1 784",
        name: "Saint Vincent and the Grenadines",
        display: false
    },
    {
        code: "ST",
        dialCode: "+239",
        name: "Sao Tome and Principe",
        display: false
    },
    {
        code: "SO",
        dialCode: "+252",
        name: "Somalia",
        display: false
    },
    {
        code: "SJ",
        dialCode: "+47",
        name: "Svalbard and Jan Mayen",
        display: false
    },
    {
        code: "SY",
        dialCode: "+963",
        name: "Syrian Arab Republic",
        display: false
    },
    {
        code: "TW",
        dialCode: "+886",
        name: "Taiwan, Province of China",
        display: false
    },
    {
        code: "TZ",
        dialCode: "+255",
        name: "Tanzania, United Republic of",
        display: false
    },
    {
        code: "TL",
        dialCode: "+670",
        name: "Timor-Leste",
        display: false
    },
    {
        code: "VE",
        dialCode: "+58",
        name: "Venezuela, Bolivarian Republic of",
        display: false
    },
    {
        code: "VN",
        dialCode: "+84",
        name: "Vietnam",
        display: false
    },
    {
        code: "VG",
        dialCode: "+1 284",
        name: "Virgin Islands, British",
        display: false
    },
    {
        code: "VI",
        dialCode: "+1 340",
        name: "Virgin Islands, U.S.",
        display: false
    }
];

countries.sort((c1, c2 ) => c1.name > c2.name ? 1 : (c2.name > c1.name) ? -1 : 0)

export const HiltiCountryConfig: CountryConfig = {
    countries: countries

};
