import { Component, OnInit, OnDestroy, AfterContentChecked, ViewEncapsulation, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { DataService } from 'src/app/shared/services/data.service';
import { delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service'
import { ParamsService } from 'src/app/shared/services/params.service';
import { Subscription } from 'rxjs';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HiltiCustomModalComponent } from 'src/app/shared/components/hilti-custom-modal/hilti-custom-modal.component';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';
import { TranslateService } from '@ngx-translate/core';
import { Cart } from '../submittals-cart/models/cart.model';
import { CartService } from '../submittals-cart/services/cart.service';

@Component({
    selector: 'app-submittals-products',
    templateUrl: './submittals-products.component.html',
    styleUrls: ['./submittals-products.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmittalsProductsComponent implements OnInit, AfterContentChecked, OnDestroy {

    public spinner$: boolean = false;
    public error$: boolean = false;
    public selectedByProductObject: Array<any> = [];
    private params: any = {};
    private observeStepperObj: Subscription;
    private observeProductObj: Subscription;
    private observeRoute: Subscription;
    private stepperObject: Array<any> = [];
    public parenterrormessage: any = {};
    private checkBoxGroup: any = {};
    public checkBoxObject: Array<any> = [];
    public productListForm = new FormGroup({});
    public imgURL: string = '';
    public selectedProductName: string = '';
    public modalRef: BsModalRef;
    cart: Cart;
    subChapterId: string;
    sectionId: string;
    assetHostName ="";
    totalSelectedCount = 0;


    ngAfterContentChecked(): void {
        this.renderer.removeClass(this.document.body, 'embedded-body');

        let routerURL = '';

        if (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) {
            routerURL = '/product-category/' + this.activatedRoute.snapshot.params['pid'];
        } else {
            routerURL = '/product-sections/' + this.activatedRoute.snapshot.params['psid'];
        }

        this.stepperObject = this.dataService.onAfterContentChecked(this.selectedByProductObject, this.stepperObject, 'STEPPER.PRODUCTS', routerURL);
    }

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private httpRequestService: HttpRequestService,
        private dataService: DataService,
        private sessionStorageService: SessionStorageService,
        private paramsService: ParamsService,
        public config: LanguageConfig,
        private translate: TranslateService,
        private modalService: BsModalService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private cartService: CartService,
        private tagService: GoogleTagManagerService
    ) {
        this.params = paramsService.getParams() || {};

        // here we are subscribing to the global stepper object to update the steps in stepper component.
        this.observeStepperObj = this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = c;
            });

        this.observeProductObj = this.dataService.currentSectionsOrProductsObject
            .pipe(delay(0))
            .subscribe((obj: any) => {
                this.disableAllSucceedingSteppers();
                this.checkBoxObject = obj.products ? obj.products.ranges : [];
                if (this.checkBoxObject.length > 0) {
                    this.spinner$ = true;
                    this.checkBoxObject.forEach((item, i) => {
                        this.checkBoxGroup[item.id] = new FormControl(false);
                        this.checkBoxObject[i].value = false;
                        this.productListForm = new FormGroup(this.checkBoxGroup, this.requireOneCheckboxesToBeCheckedValidator())
                    });
                    this.spinner$ = false;
                } else {
                    this.productListForm = new FormGroup(this.checkBoxGroup, this.requireOneCheckboxesToBeCheckedValidator())
                }
                this.loadSelectedValue();
            });
    }

    ngOnInit() {
        this.cart = this.cartService.cart;
        this.totalSelectedCount = this.cart.cartTotalProducts;
        this.renderer.removeClass(this.document.body, 'embedded-body');

        this.selectedByProductObject = this.sessionStorageService.getByProductObject();
        if (this.selectedByProductObject[1] && this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0 && this.selectedByProductObject[1].selectedItems[0]) {
            let tempUrl = this.selectedByProductObject[1].selectedItems[0].images ? this.selectedByProductObject[1].selectedItems[0].images.APQ_HC_300x300 : '';
            if (tempUrl != '') {
                this.imgURL = this.selectedByProductObject[1].selectedItems[0].assetHostName + '/' + tempUrl;
                this.assetHostName = this.selectedByProductObject[1].selectedItems[0].assetHostName;

            }
            this.selectedProductName = this.selectedByProductObject[1].selectedItems[0].values.CHA_CLASSIFICATION_PUBLICATION_DESC;
        } else {
            if (this.selectedByProductObject[2].selectedItems[0]) {
                let tempUrl = this.selectedByProductObject[2].selectedItems[0].images ? this.selectedByProductObject[2].selectedItems[0].images.APQ_HC_300x300 : '';
                if (tempUrl != '') {
                    this.imgURL = this.selectedByProductObject[2].selectedItems[0].assetHostName + '/' + tempUrl;
                    this.assetHostName = this.selectedByProductObject[2].selectedItems[0].assetHostName;
                }
                this.selectedProductName = this.selectedByProductObject[2].selectedItems[0].values.CHA_CLASSIFICATION_PUBLICATION_DESC;
            }
        }
    }

    loadSelectedValue() {
        this.observeRoute = this.activatedRoute.params.subscribe(params => {
            let subChapterId = params['pid'] ? params['pid'] : this.activatedRoute.snapshot.parent.parent.params['pcsid'];
            let chapterId = this.activatedRoute.snapshot.parent.parent.params["id"] ? this.activatedRoute.snapshot.parent.parent.params["id"] : this.activatedRoute.snapshot.parent.parent.parent.parent.params["id"];
            let sectionId = params['psid'];
            this.sectionId = sectionId;
            this.subChapterId = sectionId ? sectionId : subChapterId;
            this.loadProductLists(chapterId, subChapterId, sectionId);
        });
    }

    loadProductLists(chapterId, subChapterId, sectionId?) {
        let serviceAPI = this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/chapters/' + chapterId + '/subChapters/' + subChapterId + '/sections';
        if (sectionId) {
            serviceAPI += '/' + sectionId + '/ranges';
        }

        this.spinner$ = true;
        this.httpRequestService.getWithToken(serviceAPI)
            .subscribe(((obj: any) => {
                this.checkBoxObject = obj.ranges;
                this.checkBoxObject.forEach((item, i) => {
                    this.checkBoxGroup[item.id] = new FormControl(false);
                    this.checkBoxObject[i].value = false;
                });
                this.spinner$ = false;
                this.updateAlreadySelectedValues();
            }),
                (err => this.showErroResponse(err)));
    }

    updateAlreadySelectedValues(): void {
        const existProd = this.cart.getExistingProduct(this.subChapterId, this.sectionId);
        if (existProd) {
            this.totalSelectedCount = this.cart.cartTotalProducts - existProd[this.sectionId ? 3 : 2].selectedItems.length;
            existProd[this.sectionId ? 3 : 2].selectedItems.forEach(item => {
                const findProd = this.checkBoxObject.find(ch => ch.id === item.id);
                if (findProd) {
                    findProd.value = true;
                    let selectedItemid = {};
                    selectedItemid[findProd.id] = true;
                    this.productListForm.patchValue(selectedItemid);
                }
            });
            this.checkBoxObject.forEach((item, i) => {
                this.productListForm = new FormGroup(this.checkBoxGroup, this.requireOneCheckboxesToBeCheckedValidator())
            });
        }
        
    }

    showErroResponse(err) {
        this.spinner$ = false;
        this.error$ = true;
        this.parenterrormessage = err;
    }

    navigate() {
        let tempProdStep = this.stepperObject.filter(obj => { return obj.title === 'STEPPER.PRODUCTS' });
        if (tempProdStep[0] && tempProdStep[0].stepValue) {
            this.stepperObject = this.dataService.changeStepperStepInfo(this.stepperObject, tempProdStep)
        }

        this.dataService.changeStepperObjectGlobal(this.stepperObject);

        let selectedItemsToGenerateTabs = []
        this.checkBoxObject.forEach(selectedItem => {
            if (selectedItem.value) {
                selectedItem.stepLebel = 'Products';
                selectedItemsToGenerateTabs.push(selectedItem)
            }
        })

        let stepper = (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) ? 2 : 3;
        let tempByProductObject = {
            accId: stepper,
            TITLE: 'STEPPER.PRODUCTS',
            selectedItems: selectedItemsToGenerateTabs
        };

        this.selectedByProductObject.splice(stepper, this.selectedByProductObject.length);
        this.selectedByProductObject.push(tempByProductObject);
        this.sessionStorageService.setByProductObject(this.selectedByProductObject);
        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step5',
            'pageName': 'Documents'
        }, this.selectedByProductObject);
        this.router.navigate(['product-document', selectedItemsToGenerateTabs[0] ? selectedItemsToGenerateTabs[0].id : ''], { relativeTo: this.activatedRoute });
    }

    showSidePads(itemChecked, value) {
        let selectedItemid = {};
        selectedItemid[itemChecked.id] = value;
        this.productListForm.patchValue(selectedItemid);
        let tempProductselected: any = [];
        this.checkBoxObject.forEach(selectedItem => {
            if (selectedItem.value) {
                tempProductselected.push(selectedItem)
            }
        });
        const totaCount = this.totalSelectedCount;
        const tempSelectedCount = totaCount + tempProductselected.length;
        if (tempSelectedCount > 30) {
            const initialState = {
                imgURL: '../assets/images/alert_info.svg',
                title: 'CUSTOM_MODAL.PRODUCT_MODAL.MODAL_CONTENT',
                buttonTitle: 'CUSTOM_MODAL.PRODUCT_MODAL.MODAL_CONFIRM_BUTTON',
            };
            this.modalRef = this.modalService.show(HiltiCustomModalComponent, { class: ' modal-dialog-centered', initialState });
        }
        if (tempProductselected.length === 0) {
            this.disableAllSucceedingSteppers()
        }
    }

    removeItemFromList(itemToRemove) {
        let element = document.getElementById(itemToRemove.id);

        if (element) {
            element.click();
        }
    }

    disableAllSucceedingSteppers() {
        let tempProdStep = this.stepperObject.filter(obj => { return obj.title === 'STEPPER.PRODUCTS' });
        if (tempProdStep[0] && tempProdStep[0].stepValue) {
            this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperCurrentCount: undefined }));
            this.stepperObject = this.stepperObject.map(v => ({ ...v, stepperProgressCount: tempProdStep[0].stepValue - 1 }));
            this.dataService.changeStepperObjectGlobal(this.stepperObject);
        }
    }

    requireOneCheckboxesToBeCheckedValidator(minRequiredCount = 1, maxRequiredCount = (30 - this.totalSelectedCount)): ValidatorFn {
        return function validate(formGroup: FormGroup) {
            let checked = 0;
            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];
                if (control.value === true) {
                    checked++;
                }
            });
            if (checked < minRequiredCount || checked > maxRequiredCount) {
                return {
                    requireOneCheckboxToBeChecked: true,
                };
            }
            return null;
        };
    }

    ngOnDestroy(): void {
        this.modalService.hide(1);
        this.observeStepperObj.unsubscribe();
        this.observeProductObj.unsubscribe();
        if (this.observeRoute) {
            this.observeRoute.unsubscribe();
        }
    }
}
