import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsByProductComponent } from './submittals-by-product.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';
import { SubmittalsByProductResolver } from './submittals-by-product.resolver.service';

const productRoutes: Routes = [
  {
    path: '', component: SubmittalsByProductComponent,
    runGuardsAndResolvers: () => {
      if (sessionStorage.getItem('changeLanguage') === 'yes') {//we want to reload resolver only if the language is changed
        sessionStorage.setItem('changeLanguage', 'no');
        return true;
      } else {
        return false;
      }
    },
    resolve: { byProducts: SubmittalsByProductResolver },
    canDeactivate: [NavigationGuard],
    children: [
      {
        path: ':id',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductCategoriesModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(productRoutes)],
  exports: [RouterModule]
})
export class SubmittalsByProductRoutingModule { }
