import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SessionStorageService } from './session-storage.service';
import { ParamsService } from './params.service';
import { LogoutService } from './logout.service';

@Injectable()
export class HttpRequestService {
  private params: any = {};
  constructor(private sessionStorageService: SessionStorageService,
    private http: HttpClient,
    private logoutService: LogoutService,
    private paramsService: ParamsService
  ) {
    this.params = paramsService.getParams() ? paramsService.getParams() : {};
  }
  private static DEBUG_MODE = true;
  // Handle CRUD requests
  private createTokenWithAccessTokenPOST(): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.sessionStorageService.getAccessToken(),
      'Content-Type': 'application/json',
    });
  }

  private createTokenWithAccessToken(): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.sessionStorageService.getAccessToken(),
    });
  }

  get(path: string, params, responseType?: boolean): Observable<any> {
    const httpParams = new HttpParams({ fromObject: params });
    const options: any = { params: httpParams };
    if (responseType)
      options.observe = 'response';
    return this.http.get(path, options)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.handleError())
      );
  }

  // Wrapper methods to make calls with access token
  public getWithToken<T>(url: string, params: any = {}): Observable<T> {
    if (typeof this.sessionStorageService.getCountry === 'function' && typeof this.sessionStorageService.getLanguage === 'function') {
      params.country = this.sessionStorageService.getCountry() ? this.sessionStorageService.getCountry() : 'US';
      params.language = this.sessionStorageService.getLanguage() ? this.sessionStorageService.getLanguage() : 'en';
    } else {
      params.country = 'US';
      params.language = 'en';
    }
    const httpParams = new HttpParams({ fromObject: params });
    const options: any = { headers: this.createTokenWithAccessToken(), params: httpParams };
    return this.http
      .get<T>(url, options)
      .pipe(
        catchError(err => this.preHandleError(err))
      );
  }

  public postWithToken<T>(url: string, body?: any, params?: HttpParams): Observable<T> {
    return this.http
      .post<T>(url, body, {
        headers: this.createTokenWithAccessTokenPOST(),
        params
      })
      .pipe(
        catchError(err => this.preHandleError(err))
      );
  }
  public createSubmittal<T>(url: string, formData, params?: HttpParams): Observable<boolean> {
    return this.http
      .post<T>(url, formData, {
        headers: this.createTokenWithAccessToken(),
        params
      })
      .pipe(
        catchError(err => this.preHandleError(err))
      );
  }
  public putWithToken<T>(url: string, body?: any, params?: HttpParams): Observable<T> {
    return this.http
      .put<T>(url, body, {
        headers: this.createTokenWithAccessTokenPOST(),
        params
      })
      .pipe(
        catchError(err => this.preHandleError(err))
      );
  }

  public deleteWithToken<T>(url: string, params?: HttpParams): Observable<T> {
    return this.http
      .delete<T>(url, {
        headers: this.createTokenWithAccessToken(),
        params
      })
      .pipe(
        catchError(err => this.preHandleError(err))
      );
  }

  private preHandleError<T>(err: HttpErrorResponse | any): Observable<any> {
    if (err.status === 401) {
      this.logoutService.logout();
    }

    return throwError(err.status);
  }

  handleError() {
    return (error: HttpErrorResponse): Promise<any> => {
      if (error.status === 401) {
        this.logoutService.logout();
      }
      return throwError(error).toPromise();
    };
  }


}
