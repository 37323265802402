import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from '../../../../shared/services/google-tag-manager.service';
import { Cart } from '../../models/cart.model';

@Component({
  selector: 'app-submittals-cart-summary',
  templateUrl: './submittals-cart-summary.component.html',
  styleUrls: ['./submittals-cart-summary.component.scss']
})
export class SubmittalsCartSummaryComponent implements OnInit {

  @Input() cart: Cart;
  @Input() customCoverAndEmailSelected: boolean;
  collapseAllacc = false;
  selectedRemoveItemId: string;
  showButton = true;
  mobileDisplay = true;

  constructor(
    private router: Router,
    private tagService: GoogleTagManagerService,
    private translate: TranslateService
  ) { }

  ngOnInit() { }

  removeButton() {
    this.cart.removeCategory(this.selectedRemoveItemId);
  }

  setRemoveItemId(id: string): void {
    this.selectedRemoveItemId = id;
  }

  editCategory(category): void {
    const categoryId = category[0].id;
    const categorySectionId = category[1].selectedItems[0].id;
    const productDocumentId = category[2].selectedItems[0].id;
    const subCategoryDocumentId = category.length > 4 ? category[3].selectedItems[0].id : undefined;
    this.cart.updateSessionProduct(productDocumentId, 0);
    if (subCategoryDocumentId) {
      this.router.navigate(['by-products', categoryId, 'product-category-section', categorySectionId, 'product-sections', productDocumentId, 'product-document', subCategoryDocumentId]);
    }
    else {
      this.router.navigate(['by-products', categoryId, 'product-category', categorySectionId, 'product-document', productDocumentId]);
    }

  }

  addProductToCategory = function (category) {
    this.cart.updateSessionProduct(category[0][1].selectedItems[0].id, 1);
    this.tagService.setGMTDataLayer({
      'event': 'navigation_multi',
      'eventCategory': 'Submittals',
      'eventAction': 'Navigation Multi',
      'eventLabel': 'Add Product',
      'stepNumber': 'Step2',
      'pageName': 'Product Category'
    });

    const subcategoryId = category[0].length > 4 ? category[0][1].selectedItems[0].id : undefined;
    if (subcategoryId) {
      this.router.navigate(['by-products', category[0][0].id, 'product-category-section', subcategoryId]);
    }
    else {
      this.router.navigate(['by-products', category[0][0].id]);
    }

  }
}
