import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapModule } from './modules/ngx-bootstrap.module';
import { HttpClientModule } from '@angular/common/http';
import { HiltiUiLibraryModule } from './modules/hilti-ui-library.module';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { HiltiCookieService } from './services/hilti-cookie.service';
import { HiltiTranslateService } from './services/hilti-translate.service';
import { HttpRequestService } from './services/http-request.service';
import { HttpResponseService } from './services/http-response.service';
import { LoginService } from './services/login.service';
import { ParamsService } from './services/params.service';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from './services/session-storage.service';
import { LocalStorageService } from './services/local-storage.service';
import { WindowRefService } from './services/window-ref.service';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageConfig } from './models/language-config.model';
import { HiltiLanguageConfig } from './configs/hilti-language.config';
import { CountryConfig } from './models/country-config.model';
import { HiltiCountryConfig } from './configs/hilti-country.config';
import { BydCountry } from './models/byd-country.model';
import { HiltiBydCountryConfig } from './configs/hilti-byd-country.config';
import { HiltiCustomHeaderComponent } from './components/hilti-custom-header/hilti-custom-header.component';
import { StepperComponent } from './components/hilti-stepper/stepper.component';
import { HiltiSpinnerComponent } from './components/hilti-spinner/hilti-spinner.component';
import { HiltiPageNotFoundComponent } from './components/hilti-page-not-found/hilti-page-not-found.component';
import { HiltiCustomModalComponent } from './components/hilti-custom-modal/hilti-custom-modal.component';
import { HiltiCustomConfirmModalComponent } from './components/hilti-custom-confirm-modal/hilti-custom-confirm-modal.component';
import { NavigationGuard } from './services/navigation.guard';
import { HiltiProductImageComponent } from './components/hilti-product-image/hilti-product-image.component';
import { SessionTimeoutModalComponent } from './session-timeout-modal/session-timeout-modal.component';

export function initTranslateService(hiltiTranslateService: HiltiTranslateService) {
    return () => hiltiTranslateService.init();
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxBootstrapModule,
        HiltiUiLibraryModule,
        TranslateModule.forChild()
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxBootstrapModule,
        HttpClientModule,
        HiltiUiLibraryModule,
        HiltiCustomHeaderComponent,
        StepperComponent,
        HiltiSpinnerComponent,
        HiltiPageNotFoundComponent,
        HiltiCustomModalComponent,
        HiltiCustomConfirmModalComponent,
        HiltiProductImageComponent
    ],
    providers: [
        AuthService,
        AuthGuardService,
        NavigationGuard,
        HiltiCookieService,
        HiltiTranslateService,
        HttpRequestService,
        HttpResponseService,
        LoginService,
        ParamsService,
        CookieService,
        SessionStorageService,
        LocalStorageService,
        WindowRefService,
        {
            provide: APP_INITIALIZER,
            useFactory: initTranslateService,
            deps: [HiltiTranslateService],
            multi: true
        },
        { provide: LanguageConfig, useValue: HiltiLanguageConfig },
        { provide: CountryConfig, useValue: HiltiCountryConfig },
        { provide: BydCountry, useValue: HiltiBydCountryConfig }
    ],
    declarations: [
        HiltiCustomHeaderComponent,
        StepperComponent,
        HiltiSpinnerComponent,
        HiltiPageNotFoundComponent,
        HiltiCustomModalComponent,
        HiltiCustomConfirmModalComponent,
        HiltiProductImageComponent,
        SessionTimeoutModalComponent
    ],
    entryComponents: [HiltiCustomModalComponent, HiltiCustomConfirmModalComponent, SessionTimeoutModalComponent]
})
export class SharedModule {
}
