import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsProductsComponent } from './submittals-products.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';

const productsRoutes: Routes = [
  {
    path: '', component: SubmittalsProductsComponent,
    canDeactivate: [NavigationGuard],
    children: [
      {
        path: 'product-document',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductDocumentModule)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(productsRoutes)],
  exports: [RouterModule]
})
export class SubmittalsProductsRoutingModule { }
