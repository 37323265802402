import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-hilti-custom-confirm-modal',
  templateUrl: './hilti-custom-confirm-modal.component.html',
  styleUrls: ['./hilti-custom-confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class HiltiCustomConfirmModalComponent implements OnInit {
  public title: string = 'Modal Title';
  public imgURL: string = '';
  public confirmbuttonTitle: string = 'Ok';
  public cancelbuttonTitle: string = 'No';
  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.onClose = new Subject();
  }
  public confirm() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }
  public cancel() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
