import { Component, OnInit, ViewEncapsulation, AfterContentChecked, Renderer2, Inject, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service'
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';

@Component({
    selector: 'app-submittals-by-product',
    templateUrl: './submittals-by-product.component.html',
    styleUrls: ['./submittals-by-product.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmittalsByProductComponent implements OnInit, AfterContentChecked {
    public byProducts: any = {};
    public imgBaseURL: string = '';
    public spinner$: boolean = false;
    public error$: boolean = false;
    private selectedByProductObject: Array<any> = [];
    public parenterrormessage: object = {};

    constructor(
        public activatedRoute: ActivatedRoute,
        private router: Router,
        private sessionStorageService: SessionStorageService,
        public config: LanguageConfig,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private tagService: GoogleTagManagerService
    ) {

    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'Browser Back',
            'browserBackBtnClick': true
        });
    }

    ngAfterContentChecked(): void {
        this.renderer.addClass(this.document.body, 'embedded-body');
    }

    ngOnInit() {
        this.renderer.addClass(this.document.body, 'embedded-body');

        this.spinner$ = true;

        this.activatedRoute.data.subscribe(((obj: any) => {
            let byProducts = obj.byProducts || {};
            this.spinner$ = false;

            if (byProducts.error) {
                this.showErrorModal(byProducts.error);
                this.byProducts = [];
            } else {
                this.error$ = false;
                this.imgBaseURL = byProducts.assetHostName;
                this.byProducts = byProducts;
            }
        }),
            (err => this.showErrorModal(err)));

        this.selectedByProductObject = this.sessionStorageService.getByProductObject() || [];
        this.sessionStorageService.removeIsSubmittalsSubmitted();
    }

    showErrorModal(err) {
        this.spinner$ = false;
        this.error$ = true;
        this.parenterrormessage = err;
    }

    navigateToCategories(id, selectedProduct) {
        selectedProduct.assetHostName = this.imgBaseURL;
        selectedProduct.accId = 0;
        selectedProduct.TITLE = selectedProduct.values.CHA_CLASSIFICATION_PUBLICATION_DESC
        this.selectedByProductObject = [selectedProduct];
        this.sessionStorageService.setByProductObject(this.selectedByProductObject);

        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step2',
            'pageName': 'Product Category'
        }, this.selectedByProductObject);

        this.router.navigate(['/by-products', id]);
    }
}
