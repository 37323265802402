import { Component, OnInit, ViewEncapsulation, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { DataService } from 'src/app/shared/services/data.service';
import { delay } from 'rxjs/operators';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { ParamsService } from 'src/app/shared/services/params.service';
import { Subscription } from 'rxjs';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HiltiCustomModalComponent } from 'src/app/shared/components/hilti-custom-modal/hilti-custom-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { GoogleTagManagerService } from '../../shared/services/google-tag-manager.service';
import { Router } from '@angular/router';
import { Cart } from './models/cart.model';
import { CartService } from './services/cart.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-submittals-cart',
    templateUrl: './submittals-cart.component.html',
    styleUrls: ['./submittals-cart.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmittalsCartComponent implements OnInit {
    public stepperObject: Array<any> = [];
    public collapseAllacc: boolean = true;
    public accordionsArray: Array<any> = [];
    private params: any = {};
    private observeStepperObj: Subscription;
    public spinner$: boolean = false;
    public modalRef: BsModalRef;
    public accArray_products: Array<any> = [];
    public isShowOption: boolean = false;
    public cart: Cart;
    public optionsForm: FormGroup;
    public selectedByProductObject: Array<any> = [];
    customCoverAndEmailSelected = false;
    isSubmitted = false;


    constructor(
        @Inject(DOCUMENT) private document: Document,
        private sessionStorageService: SessionStorageService,
        private dataService: DataService,
        private httpRequestService: HttpRequestService,
        private paramsService: ParamsService,
        private translate: TranslateService,
        public config: LanguageConfig,
        private modalService: BsModalService,
        private renderer: Renderer2,
        private tagService: GoogleTagManagerService,
        private router: Router,
        private cartService: CartService
    ) {
        this.params = this.paramsService.getParams() || {};
        this.observeStepperObj = this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = c;
            });
    }

    ngOnInit() {
        this.cart = this.cartService.cart;
        this.isSubmitted = this.sessionStorageService.getIsSubmittalsSubmitted() || false;
        if(this.isSubmitted){
            this.cart.clearCart();
        }
        this.optionsForm = new FormGroup({
            'deliverMethodRadioBtn': new FormControl('viewOnline'),
            'coverPageRadioBtn': new FormControl('hiltiCoverPage'),
            'pageNumberRadioBtn': new FormControl('yes'),
        });
    }

    onCustomCoverAndEmailSelectedChange(event: boolean) {
        this.customCoverAndEmailSelected = event;
    }

    navigateToProducts = function () {
        this.dataService.changeStepperObjectGlobal([]);
        this.tagService.setGMTDataLayer({
            'event': 'navigation_multi',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation Multi',
            'eventLabel': 'Add Product Category',
            'stepNumber': 'Step1',
            'pageName': 'HomePage'
        });
        this.router.navigate(['/by-products']);
    }

    createSubmittals(): void {
        this.cartService.generateDeliveryOptions(this.optionsForm);
        let postDataToCreateSubmittals: any = {};
        postDataToCreateSubmittals.userName = this.sessionStorageService.getUserName();
        postDataToCreateSubmittals.timeStamp = new Date().getTime();
        postDataToCreateSubmittals.deliveryMethod = 'viewOnline'; //setting default value
        postDataToCreateSubmittals.documentURLs = [];
        postDataToCreateSubmittals.tocData = [];

        if (this.cart.cartCollection.length) {
            postDataToCreateSubmittals = this.cartService.loadDocumentDataWithTOC(postDataToCreateSubmittals)
            const formData: FormData = new FormData();
            this.selectedByProductObject = this.sessionStorageService.getByProductObject();
            const tempOptionStep = this.selectedByProductObject.filter(obj => { return obj.TITLE === 'STEPPER.OPTIONS' });
            if (tempOptionStep[0] && tempOptionStep[0].selectedItems[0] && tempOptionStep[0].selectedItems[0].selectedDocuments[0]) {
                postDataToCreateSubmittals.deliveryMethod = tempOptionStep[0].selectedItems[0].selectedDocuments[0].value;
                if (postDataToCreateSubmittals.deliveryMethod == 'emailSub') {
                    postDataToCreateSubmittals.deliveryToEmail = tempOptionStep[0].selectedItems[0].selectedDocuments[0].email;
                }
            }
            if (tempOptionStep[0] && tempOptionStep[0].selectedItems[1] && tempOptionStep[0].selectedItems[1].selectedDocuments[0]) {
                const coverPageValue = tempOptionStep[0].selectedItems[1].selectedDocuments[0].value;
                if (coverPageValue === 'hiltiCoverPage') {
                    postDataToCreateSubmittals.isHiltiCoverPage = true;
                }
                if (coverPageValue === 'customCoverPage') {
                    let coverPage: any = '';
                    postDataToCreateSubmittals.isCustomCoverPage = true;
                    this.dataService.currentCustomCoverPageFile.subscribe(file => {
                        coverPage = file;
                        formData.append('customCoverPage', coverPage);
                    });
                }
            }
            if (tempOptionStep[0] && tempOptionStep[0].selectedItems[2] && tempOptionStep[0].selectedItems[2].selectedDocuments[0]) {
                if (tempOptionStep[0].selectedItems[2].selectedDocuments[0].value === 'yes') {
                    postDataToCreateSubmittals.includePageNumbers = true;
                } else {
                    postDataToCreateSubmittals.includePageNumbers = false;
                }
            }

            this.spinner$ = true;
            let httpParams = new HttpParams({
                fromObject:
                {
                    language: this.sessionStorageService.getLanguage() ? this.sessionStorageService.getLanguage() : 'en',
                    country: this.sessionStorageService.getCountry() ? this.sessionStorageService.getCountry() : 'US'
                }
            });
            formData.append('createSubmittalRequest', JSON.stringify(postDataToCreateSubmittals));
            this.httpRequestService.createSubmittal(this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/createSubmittal', formData, httpParams)
                .subscribe(((obj: any) => {
                    this.spinner$ = false;

                    if (obj.success) {
                        this.tagService.setGMTDataLayer({
                            'event': 'submittal_success',
                            'eventCategory': 'Submittals',
                            'eventAction': this.cart.cartTotalProducts > 1 ? 'Create Multi' : 'Create Single',
                            'eventLabel': 'Success',
                            'stepNumber': 'Step8',
                            'pageName': 'Summary'
                        }, this.selectedByProductObject);

                        /* if viewOnline is selected the PDF will be open on a new tab */
                        if (postDataToCreateSubmittals.deliveryMethod === 'viewOnline') {
                            window.open(obj.success + "#page=1", '_blank');
                        }

                        this.showModal('success');
                    } else {
                        this.showModal('error');
                    }
                    this.sessionStorageService.setIsSubmittalsSubmitted(true);
                }),
                    (err => {
                        this.spinner$ = false;
                        this.sessionStorageService.setIsSubmittalsSubmitted(true);
                        this.showModal('error');
                    }));
        } else {
            this.showModal('error');
        }
    }


    selectSubmittalsFromMob(): void {
        this.isShowOption = true;
    }


    showModal(isModal: string) {
        let initialState;

        if (isModal === 'success') {
            initialState = {
                imgURL: '../assets/images/alert_positive.svg',
                title: 'CUSTOM_MODAL.SUBMITTAL_MODAL.MODAL_CONTENT',
                buttonTitle: 'CUSTOM_MODAL.SUBMITTAL_MODAL.MODAL_CONFIRM_BUTTON'
            };
        } else {
            initialState = {
                imgURL: '../assets/images/alert_error.svg',
                title: 'CUSTOM_MODAL.SUBMITTAL_MODAL.SUBMITTAL_NOT_COMPLETD',
                buttonTitle: 'CUSTOM_MODAL.SUBMITTAL_MODAL.MODAL_TRY_AGAIN_BUTTON'
            };
        }
        this.modalRef = this.modalService.show(HiltiCustomModalComponent, { class: ' modal-dialog-centered', initialState });

        this.modalService.onHide.subscribe(() => {
            if (this.modalRef) {
                this.navigateToHome();
                this.modalRef = null;
            }
        });

    }

    navigateToHome() {
        this.dataService.changeStepperObjectGlobal([]);
        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step1',
            'pageName': 'HomePage'
        });
        this.router.navigate(['/by-products']);
    }

    ngOnDestroy(): void {
        this.modalService.hide(1);
        this.renderer.removeClass(this.document.body, 'summary-body');
        this.observeStepperObj.unsubscribe();
        if(this.sessionStorageService.getIsSubmittalsSubmitted()){
            this.cart.clearCart();
        }
    }
}
