import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { HttpParams } from '@angular/common/http';
import { ParamsService } from './params.service';
import { EnvVars } from '../models/envvars.model';
import { SessionStorageService } from './session-storage.service';
import { WindowRefService } from './window-ref.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private params: EnvVars;
  constructor(
    private localStorageService: LocalStorageService,
    private windowRef: WindowRefService,
    private paramsService: ParamsService,
    private sessionStorageService: SessionStorageService
  ) {
    this.params = paramsService.getParams();

  }
  private getLogoutURL(): string {
    return this.params.CLOUDSSO_BASE_URL + '/logout?';
  }
 
  
  // Use browser form to submit logout form as XHR not working with cookies passing
  public logout(): void {
    const accessToken = this.sessionStorageService.getAccessToken();

    this.localStorageService.clearAll();
    this.sessionStorageService.removeAllSessions();
    
    const formFields = {
      client_id: this.params.APP_CLIENT_ID,
      access_token: accessToken,
      logout_uri: this.params.APP_LOGOUT_REDIRECT_URL,
    };
    const method = 'POST';
    const action = `${this.getLogoutURL()}/logout`;
    const form: HTMLFormElement = this.createForm(method, action, formFields);
    window.document.body.appendChild(form);
    form.submit();

  }

  public createForm(
    method: string,
    action: string,
    fields: { [index: string]: string }
  ): HTMLFormElement {
    const form: HTMLFormElement = document.createElement('form');
    form.method = method.toUpperCase();
    form.action = action;

    Object.keys(fields).forEach((key: string) => {
      const input: HTMLInputElement = document.createElement('input');
      input.name = key;
      input.value = fields[key];
      input.type = 'hidden';
      form.appendChild(input);
    });

    return form;
  }




}
