import {Injectable} from '@angular/core';
import {EnvVars} from '../models/envvars.model';
import {ParamsService} from './params.service';
import {SessionStorageService} from './session-storage.service';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  private originApplicationURL = 'origin_application_url';
  private params: EnvVars;

  constructor(private paramsService: ParamsService,
              private sessionStorageService: SessionStorageService) {
    this.params = paramsService.getParams();
  }


  getOriginApplicationURL(): string {

    if (localStorage.getItem(this.originApplicationURL) === null) {
      const currentCountry = this.sessionStorageService.getCountry() ? this.sessionStorageService.getCountry() : 'US';
      return this.params['SOURCE_APP_URL'] + '&country=' + currentCountry;
    }
    return localStorage.getItem(this.originApplicationURL);
  }

  setOriginApplicationURL(value: string) {
    localStorage.setItem(this.originApplicationURL, value);
  }

  removeOriginApplicationURL() {
    localStorage.removeItem(this.originApplicationURL);
  }

  clearAll(): void {
    this.removeOriginApplicationURL();
  }

}
