import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Cart } from 'src/app/modules/submittals-cart/models/cart.model';
import { DataService } from 'src/app/shared/services/data.service';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';
import { SessionStorageService } from '../../../shared/services/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class CartService {

    _cart: Cart;
    _selectedByProductObject: Array<any> = [];
    _stepperObject: Array<any> = [];

    constructor(private sessionService: SessionStorageService,
        private dataService: DataService,
        private translate: TranslateService,
        private tagService: GoogleTagManagerService) { }

    /**
     * @method setCartObject Initiate the cart model andn can be accessed 
     */

    setCartObject(): void {
        this._cart = new Cart(this.sessionService);
    }

    updateCart(): void {
        this._cart.updateCart();
    }

    get cart(): Cart {
        return this._cart;
    }

    generateDeliveryOptions(form) {
        /* creating option object to save in session to display summary and create submittals */
        let tempSelectedItems: Array<any> = [
            {
                name: 'Delivery Method',
                TITLE: 'OPTIONS.DELIVERY_METHOD.TITLE',
                selectedDocuments: [
                    {
                        TITLE: form.value.deliverMethodRadioBtn === 'viewOnline' ? 'OPTIONS.DELIVERY_METHOD.VIEW_ONLINE_DOWNLOAD' : 'OPTIONS.DELIVERY_METHOD.EMAIL_SUBMITTAL.TITLE',
                        value: form.value.deliverMethodRadioBtn,
                        email: ''
                    }
                ]
            },
            {
                name: 'Cover Page',
                TITLE: 'OPTIONS.COVER_PAGE.TITLE',
                selectedDocuments: [
                    {
                        TITLE: form.value.coverPageRadioBtn === 'hiltiCoverPage' ? 'OPTIONS.COVER_PAGE.HILTI_COVER_PAGE' : (form.value.coverPageRadioBtn === 'customCoverPage' ? 'OPTIONS.COVER_PAGE.CUSTOM_COVER_PAGE' : 'OPTIONS.COVER_PAGE.NO_COVER_PAGE'),
                        value: form.value.coverPageRadioBtn
                    }
                ]
            },
            {
                name: 'Include page Numbers?',
                TITLE: 'OPTIONS.PAGE_NUMBER.TITLE',
                selectedDocuments: [
                    {
                        TITLE: form.value.pageNumberRadioBtn === 'yes' ? 'OPTIONS.PAGE_NUMBER.YES' : 'OPTIONS.PAGE_NUMBER.NO',
                        value: form.value.pageNumberRadioBtn
                    }
                ]
            }
        ];
        if (form.value && form.value.userEmailInput) {
            tempSelectedItems[0].selectedDocuments[0].email = form.value.userEmailInput;
        }

        /* checked if the sub category step exists on the session object and accordingly saving the options in the same object */
        this._selectedByProductObject = this.sessionService.getByProductObject();
        let stepper = (this._selectedByProductObject.filter(obj => { return obj.TITLE === 'STEPPER.SECTIONS' }).length == 0) ? 4 : 5;

        let tempByProductObject = {
            TITLE: 'STEPPER.OPTIONS',
            accId: stepper,
            selectedItems: tempSelectedItems
        }

        this._selectedByProductObject.splice(stepper, this._selectedByProductObject.length);

        this._selectedByProductObject.push(tempByProductObject);

        this.sessionService.setByProductObject(this._selectedByProductObject);

        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step7',
            'pageName': 'Summary'
        }, this._selectedByProductObject);

        /* This logic is to set the stepper values. On click of next the stepper progress will be incresed.
        We are also checking if the user has clicked on already passed stepper. If so the current step will be selected and will be increased by one on click of next, in which case stepper progress will be at the same value */
        let tempOptStep = this._stepperObject.filter(obj => { return obj.title === 'STEPPER.OPTIONS' });
        if (tempOptStep[0] && tempOptStep[0].stepValue) {
            this._stepperObject = this.dataService.changeStepperStepInfo(this._stepperObject, tempOptStep);
        }
        /* Stepper object is changed globally */
        this.dataService.changeStepperObjectGlobal(this._stepperObject);
    }

    getDocName(array) {
        let onlyNameObj: any = [];
        array.forEach(docObj => {
            if (docObj.value) {
                onlyNameObj.push(docObj.name)
            }
        });
        return onlyNameObj;
    }

    loadDocumentDataWithTOC(postDataToCreateSubmittals) {
        this.cart.cartCollection.forEach(cartItem => {
            const cartDocStep = cartItem.find(obj => { return obj.TITLE === 'STEPPER.DOCUMENTS' });
            if (cartDocStep) {
                cartDocStep.selectedItems.forEach(selProd => {
                    let tocTempData: any = {};
                    let subTypeTempObj: any = {};
                    selProd.selectedDocuments.forEach(doc => {
                        doc.selectedPDFs.forEach(pdf => {
                            if (pdf.value && pdf.assetPushLocations) {
                                let formatedURL = pdf.pdfLink.replace('/' + pdf.assetPushLocations.APQ_HC_RAW, '|' + pdf.assetPushLocations.APQ_HC_RAW);
                                postDataToCreateSubmittals.documentURLs.push(formatedURL);
                            }
                        });
                        if (doc.selectedPDFs.length > 0 && this.getDocName(doc.selectedPDFs).length) {
                            if (doc.TITLE === 'TABS.APPROVALS_CERTIFICATES') {
                                subTypeTempObj[this.translate.instant('TABS.APPROVALS_CERTIFICATES')] = this.getDocName(doc.selectedPDFs);
                            }
                            if (doc.TITLE === 'TABS.SAFETY_DATA_SHEETS') {
                                subTypeTempObj[this.translate.instant('TABS.SAFETY_DATA_SHEETS')] = this.getDocName(doc.selectedPDFs);
                            }
                            if (doc.TITLE === 'TABS.GREEN') {
                                subTypeTempObj[this.translate.instant('TABS.GREEN')] = this.getDocName(doc.selectedPDFs);
                            }
                            if (doc.TITLE === 'TABS.GENARAL_INFORMATION') {
                                subTypeTempObj[this.translate.instant('TABS.GENARAL_INFORMATION')] = this.getDocName(doc.selectedPDFs);
                            }
                        }
                    });

                    if (Object.keys(subTypeTempObj).length != 0) {
                        tocTempData[selProd.name ? selProd.name : ''] = [subTypeTempObj];
                    }

                    if (Object.keys(tocTempData).length != 0) {
                        postDataToCreateSubmittals.tocData.push(tocTempData);
                    }
                });
            }
        });

        return postDataToCreateSubmittals
    }


    // adding validator to set estriction as 6 email ids and check valid email
    emailCustomValidator = (control: FormControl) => {
        if (control.value) {
            let v = control.value.trim();
            if (v.length) {
                let emailArr = v.split(';');

                let isValid = emailArr.filter(item => {
                    return item.split('@').length > 2;
                });

                if (isValid.length) {
                    return {
                        isValid: 'Invalid Email'
                    };
                }

                let isErrEmailArr = emailArr.filter(item => {
                    return item !== '' && !new RegExp(/^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/).test(item.trim())
                });

                if (isErrEmailArr.length) {
                    return {
                        isValid: 'Invalid email'
                    };
                }

                let isValidEmailArr = emailArr.filter(item => {
                    return item !== '' && new RegExp(/^\w+([\.-]\w+)*@\w+([\.-]\w+)*(\.\w{2,3})+$/).test(item.trim())
                });

                if (isValidEmailArr.length > 5) {
                    return {
                        isMaxEmails: 'Only 5 email id allowed'
                    };
                }
            }
        } else {
            return {
                isNull: 'Email is mandatory'
            };
        }
        return null;
    }
}
