import { Injectable } from '@angular/core';
import { HiltiCookieService } from './hilti-cookie.service';
import { SessionStorageService } from './session-storage.service';
import { ParamsService } from './params.service';
import { PKCEHandler } from '../handlers/PKCEHandler';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private hiltiCookieService: HiltiCookieService,
    private sessionStorageService: SessionStorageService,
    private paramsService: ParamsService) {

  }

  public async login(countryCode?: string, languageCode?: string) {
    if (window.location.origin.includes(".ca")) {
      this.sessionStorageService.setCountry('CA');
    } else {
      this.sessionStorageService.setCountry('US');
    }

    const params = this.paramsService.getParams();
    const randomCaptcha = this.sessionStorageService.setRandomCaptcha();
    const countrySession = countryCode ? countryCode : this.getContryCodeFromSession();
    const codeVerifier = PKCEHandler.generateCodeVerifier();
    const codeChallenge = await PKCEHandler.generateCodeChallenge(codeVerifier);
    const originURL = window.location.origin + '/oauth_callback';


    this.sessionStorageService.setCodeVerifierToken(encodeURIComponent(codeVerifier))

    const uri = ''.concat(params['CLOUDSSO_BASE_URL'],
      '/authorize',
      '?client_id=', params['APP_CLIENT_ID'],
      '&redirect_uri=', encodeURIComponent(originURL),
      '&response_type=', 'code',
      '&scope=', 'HC.Request.AllScopes',
      '&code_challenge=', encodeURIComponent(codeChallenge),
      '&code_challenge_method=S256',
      '&state=', randomCaptcha,
      '&country=', countrySession);

    window.location.href = uri;
  }

  private getContryCodeFromSession(): string {
    return this.sessionStorageService.getCountry() ? this.sessionStorageService.getCountry() : 'US';
  }
  private getContryCodeFromCookie(): string {
    return this.hiltiCookieService.getCountryCookie() ? this.hiltiCookieService.getCountryCookie() : 'US';
  }
  private getLanguageCodeFromCookie(): string {
    return this.hiltiCookieService.getLocaleCookie() ? this.hiltiCookieService.getLocaleCookie() : 'en-US';
  }
}
