import { Component, OnInit, Input } from '@angular/core';
import { LanguageConfig } from '../../models/language-config.model';

@Component({
  selector: 'hilti-page-not-found',
  templateUrl: './hilti-page-not-found.component.html',
  styleUrls: ['./hilti-page-not-found.component.scss']
})
export class HiltiPageNotFoundComponent implements OnInit {

  @Input() errormessage: any = {};
  @Input() errorcode;

  constructor(
    public config: LanguageConfig
  ) { }

  ngOnInit() {
  }
}
