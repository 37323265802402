import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SessionStorageService } from '../../shared/services/session-storage.service';
import { LoginService } from '../../shared/services/login.service';
import { GoogleTagManagerService } from '../../shared/services/google-tag-manager.service';
import { ParamsService } from '../../shared/services/params.service';

@Component({
    selector: 'auth',
    template: `
    <hilti-spinner></hilti-spinner>`
})
export class AuthComponent implements OnInit {

    private token: string;
    private state: string;

    constructor(
        private router: Router,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private sessionStorageService: SessionStorageService,
        private loginService: LoginService,
        private tagService: GoogleTagManagerService,
        private paramsService: ParamsService
    ) {
        this.token = this.activatedRoute.snapshot.params.code;
        this.state = this.activatedRoute.snapshot.params.state;
        this.activatedRoute.queryParams.subscribe(res => {
            this.token = res.code;
            this.state = res.state;
        });
    }

    ngOnInit() {
        this.checkAndSetProviders();
        if (this.checkStateCaptcha()) {
            this.authService.exchangeToken(this.token)
                .subscribe((response) => {
                    this.tagService.setGMTDataLayer({
                        'stepNumber': 'Step1',
                        'pageName': 'HomePage'
                    });

                    this.router.navigate([this.authService.getParams().APP_LOGIN_REDIRECT_URL || '']);
                    this.router.navigate(['/by-products']);
                }, (error) => {
                    this.tagService.setGMTDataLayer();

                    console.log('Service responded with error. Redirecting back to auth.' + error);
                    this.loginService.login();
                }
                );
        } else {
            this.tagService.setGMTDataLayer();

            console.log('OAuth state captcha does not match.');
            this.loginService.login();
        }
    }

    checkStateCaptcha() {
        return this.sessionStorageService.getCaptcha() === this.state;
    }

    checkAndSetProviders(): void {
        window.location.origin.includes(".ca") ?
            this.paramsService.updateParams('ca')
            :
            {};
        this.authService.updateParams(this.paramsService.getParams());
    }
}
