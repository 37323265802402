import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsProductDocumentComponent } from './submittals-product-document.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';

const productDocRoutes: Routes = [
  {
    path: '', component: SubmittalsProductDocumentComponent,
    canDeactivate: [NavigationGuard],
    children: [
      {
        path: ':DocId',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductDocumentListModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(productDocRoutes)],
  exports: [RouterModule]
})
export class SubmittalsProductDocumentRoutingModule { }
