import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsProductSectionsComponent } from './submittals-product-sections.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';

const productCatSecRoutes: Routes = [
  {
    path: '', component: SubmittalsProductSectionsComponent,
    canDeactivate: [NavigationGuard],
    children: [
      {
        path: 'product-sections/:psid',
        canDeactivate: [NavigationGuard],
        loadChildren: () => import(`../index`).then(m => m.SubmittalsProductsModule)
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(productCatSecRoutes)],
  exports: [RouterModule],
})
export class SubmittalsProductSectionsRoutingModule { }
