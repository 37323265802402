/**
 * Generates a code verifier for the PKCE OAuth 2.0 flow.
*/
export class PKCEHandler {
  
   /**
     * The code verifier is a cryptographically random string using
     * 
     * @returns {string} A cryptographically random string that adheres to the PKCE specifications.
   */
  static generateCodeVerifier(): string {
    const randomValues = new Uint32Array(32);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
  }
  
  /**
   * Generates a code challenge derived from the provided code verifier.
   * 
   * The code challenge is produced by hashing the code verifier using SHA-256, 
   * then encoding the hash value in Base64URL format.
   * 
   * @param {string} verifier - The code verifier generated from `generateCodeVerifier()`.
   * @returns {Promise<string>} A Base64URL-encoded string derived from the SHA-256 hash of the verifier.
  */
  static async generateCodeChallenge(verifier: string): Promise<string> {
      const arrayBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(verifier));
      const hashArray = Array.from(new Uint8Array(arrayBuffer));
      const base64String = btoa(String.fromCharCode(...hashArray));
      const base64UrlString = base64String
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '');
      
      return base64UrlString;
  }
}
