import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmittalsProductSectionsRoutingModule } from './submittals-product-sections-routing.module';
import { SubmittalsProductSectionsComponent } from './submittals-product-sections.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SubmittalsProductSectionsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SubmittalsProductSectionsRoutingModule,
    SharedModule
  ]
})
export class SubmittalsProductSectionsModule { }
