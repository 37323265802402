import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {SessionStorageService} from './session-storage.service';
import {LoginService} from './login.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LocalStorageService} from './local-storage.service';
import {ParamsService} from './params.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService,
              private router: Router,
              private sessionStorageService: SessionStorageService,
              private localStorageService: LocalStorageService,
              private paramsService: ParamsService,
              private loginService: LoginService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    const params = this.getParams(url.toString());

    return this.checkLogin(params);
  }

  checkLogin(urlParams: any): Observable<boolean> {
    return this.authService.validateAccessToken().pipe(
      map((res: any) => {
        if (res === true) {
          this.sessionStorageService.setIsLoggedIn('true');
          return true;
        } else {
          const countryCode = urlParams['country'];
          const languageCode = urlParams['lang'];

          this.loginService.login(countryCode, languageCode);
          return false;
        }
      })
    );
  }

  private getParams(url: string): any {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }
}
