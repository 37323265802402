import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { RootComponent } from './auth/root/root.component';
import { HttpLoaderFactory } from './shared/factory/http-loader.factory';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthModule } from './auth/auth.module';

import {
  SubmittalsProductCategoriesModule,
  SubmittalsProductsModule,
  SubmittalsByProductModule,
  SubmittalsProductDocumentModule,
  SubmittalsProductDocumentListModule,
  SubmittalsProductSectionsModule
} from './modules';

import { DataService } from './shared/services/data.service';
import { SubmittalsCartModule } from './modules/submittals-cart/submittals-cart.module';
import { CartService } from './modules/submittals-cart/services/cart.service';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      useDefaultLang: true
    }),
    AuthModule,

    //app modules
    SubmittalsProductCategoriesModule,
    SubmittalsProductsModule,
    SubmittalsByProductModule,
    SubmittalsProductDocumentModule,
    SubmittalsProductDocumentListModule,
    SubmittalsProductSectionsModule,
    SubmittalsCartModule
  ],
  providers: [
    DataService,
    CartService
  ],
  bootstrap: [RootComponent],
})
export class AppModule {
}

