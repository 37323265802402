import { SessionStorageService } from "src/app/shared/services/session-storage.service";

export class Cart {

    private _cartCollection: any[] = [];
    private _cartGroupCollection;
    private _cartTotalProducts: number = 0;
    sessionService: SessionStorageService;

    constructor(sessionService) {
        this.sessionService = sessionService;
        this.updateCart();
    }


    get cartItems(): any[] {
        return this._cartGroupCollection;
    }


    get cartCollection(): any[] {
        return this._cartCollection;
    }


    get cartTotalProducts(): number {
        return this._cartTotalProducts;
    }

    updateSessionProduct(id: string, type: number) {
        const prod = this._cartCollection.find(item => item[type === 0 ? 2 : 1].selectedItems[0].id === id)
        this.sessionService.setByProductObject(prod);
    }


    updateCart(): void {
        const cartObject = this.sessionService.getByCartProductObject() || [];
        cartObject.forEach(product => {
            const isExist = this._cartCollection.findIndex(item => {
                if(item.length > 4){
                   return item[2].selectedItems[0].id === product[2].selectedItems[0].id
                }
                return item[1].selectedItems[0].id === product[1].selectedItems[0].id
            });
            if (isExist === -1) {
                this._cartCollection.push(product);
            }
            else {
                this._cartCollection[isExist] = product;
            }
        });
        this.groupCartCollection();
    }

    clearCart(): void {
        this.sessionService.setByProductObject([]);
        this.sessionService.setByCartProductObject([]);
        this._cartCollection = [];
        this._cartTotalProducts = 0;
    }


    groupCartCollection(): void {
        this._cartGroupCollection = this._cartCollection.reduce((r, a) => {
            r[a[0].id] = [...r[a[0].id] || [], a];
            return r;
        }, []);

        this._cartTotalProducts = 0;
        this._cartCollection.forEach(product => {
            this._cartTotalProducts += product[3].selectedItems.length;
        });
        this.sessionService.setByCartProductObject(this.cartCollection);
    }

    getExistingProduct(id: string, sectionId: string): any {
        return this._cartCollection.find(item => item[sectionId ? 2 : 1].selectedItems[0].id == id);
    }

    removeCategory(id: string): void {
        const existIndex = this._cartCollection.findIndex(item => item[1].selectedItems[0].id == id);
        if (existIndex !== -1) {
            this._cartCollection.splice(existIndex, 1);
            this.groupCartCollection();
        }
    }

}