import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubmittalsProductDocumentListComponent } from './submittals-product-document-list.component';
import { NavigationGuard } from 'src/app/shared/services/navigation.guard';

const productDocumentListsRoutes: Routes = [
  {
    path: '', component: SubmittalsProductDocumentListComponent,
    canDeactivate: [NavigationGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(productDocumentListsRoutes)],
  exports: [RouterModule]
})
export class SubmittalsProductDocumentListRoutingModule { }
