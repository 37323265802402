import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationGuard implements CanDeactivate<any> {

  constructor(private dataService: DataService) { }

  canDeactivate(component: any) {
    // will prevent user from going back
    this.dataService.currentBackButtonState.subscribe(state => {
      if (state) {
        this.dataService.changeBackButtonState(false);
        // push current state again to prevent further attempts.
        history.pushState(null, null, location.href);
        return true;
      } else {
        return false;
      }
    });

    return true;
  }

}
