import { NgModule } from '@angular/core';
import {
  ActionItemModule,
  ButtonModule,
  CheckboxModule,
  HeaderModule,
  ListItemModule,
  MenuModule,
  RadioButtonModule,
  TextInputModule,
  CardModule,
  DropdownModule
} from 'hilti-ui-library';

@NgModule({
  imports: [

    // Hilti UI Components
    HeaderModule,
    ActionItemModule,
    MenuModule,
    ListItemModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    TextInputModule,
    CardModule,
    DropdownModule
  ],
  exports: [
    // Hilti UI Components
    HeaderModule,
    ActionItemModule,
    MenuModule,
    ListItemModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    TextInputModule,
    CardModule,
    DropdownModule
  ]
})
export class HiltiUiLibraryModule {
}
