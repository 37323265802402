import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {
  constructor(private router: Router) {
  }

  // Wrapper for changing URL through window.location.href
  // Use to change the url on current tab
  public changeURL(url: string): void {
    // Trigger lifecycle hook first
    this.router.ngOnDestroy();
    // this.router.navigate(['/login']);
    window.location.href = url;
  }

  // Use to open a new tab with the url provided
  public open(url: string): void {
    window.open(url, '_blank');
  }

  // Wrapper for window.onbeforeunload event
  public setOnBeforeUnload(callback: any): void {
    window.onbeforeunload = callback;
  }
}
