import { Inject, Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Language } from '../models/language.model';
import { LanguageConfig } from '../models/language-config.model';
import { TranslateService } from '@ngx-translate/core';
import { HiltiCookieService } from './hilti-cookie.service';
import { Cookie } from '../models/cookie.model';
import { SessionStorageService } from './session-storage.service';

@Injectable()
export class HiltiTranslateService {

  private config: LanguageConfig;
  private translateSubject: BehaviorSubject<Language>;
  private cookie: Cookie;

  constructor(@Inject(LanguageConfig) config: LanguageConfig,
    private translateService: TranslateService,
    private haCookieService: HiltiCookieService,
    private sessionStorageService: SessionStorageService) {
    this.config = config;
    this.translateSubject = new BehaviorSubject(config.defaultLang);
  }

  init(): Promise<any> {
    this.translateService.setDefaultLang(this.config.defaultLang ? this.config.defaultLang.value : '');
    let lang = this.haCookieService.getLocaleCookie();
    if (lang) {
      lang = lang.replace('_', ' -').toLowerCase();
      return this.use(this.config.availableLangs.find(data => {
        return data.value.toLowerCase() === lang.toLocaleLowerCase();
      }) || this.config.defaultLang);
    } else {
      return this.use(this.config.defaultLang);
    }
  }

  availableLangs(): Array<Language> {
    return this.config.availableLangs;
  }

  currentLang(): Observable<Language> {
    return this.translateSubject.asObservable();
  }

  getCurrentLang(): Language {
    return this.translateSubject.getValue();
  }

  get(key: string, params?: any): Observable<string> {
    return this.translateService.get(key, params);
  }

  use(language: Language): Promise<any> {
    if (language) {
      return this.translateService.use(language.value)
        .toPromise()
        .then(() => {
          this.translateSubject.next(language);
          this.haCookieService.setLocaleCookie(language.value);
          this.sessionStorageService.setLanguage(language.value)
        })
        .catch(() => {
          if (this.config.defaultLang !== language) {
            return this.use(this.config.defaultLang);
          } else {
            return Promise.reject(language);
          }
        });
    } else {
      return Promise.reject(language);
    }


  }
}
