import { Component, OnInit, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';

@Component({
  selector: 'app-hilti-product-image',
  templateUrl: './hilti-product-image.component.html',
  styleUrls: ['./hilti-product-image.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HiltiProductImageComponent implements OnInit {

  @Input() imgSrc: Array<{}>;
  @Input() assetHostName : string;
  src: string;
  loaded = true;
  modalRef: BsModalRef;

  constructor(private imageModalService: BsModalService) { }

  ngOnInit() {
    this.src = this.imgSrc ? `${this.assetHostName}/${this.imgSrc['APQ_HC_70x50']}` : '';
  }

  getImageSrc(): string {
    return this.src;
  }

  setDefaultImage(): void {
    this.loaded = false;
    this.src = `../../../assets/images/Image 89.png`;
  }


  openImageModal(template: TemplateRef<any>) {
    if (this.loaded) {
      const config: ModalOptions = { class: 'modal-md modal-dialog-centered modal-product-image' };
      this.modalRef = this.imageModalService.show(template, config);
    }
  }

  getModalImageSrc(): string {
    return `${this.assetHostName}/${this.imgSrc['APQ_HC_830x515']}`;
  }
}
