import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ParamsService } from '../../shared/services/params.service';
import { ListItemEvent } from 'hilti-ui-library/lib/list-item/model/list-item-event.model';
import { ActionItemWithCallback } from 'hilti-ui-library/lib/action-item/model/action-item-with-callback.model';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { DataService } from 'src/app/shared/services/data.service';

declare var envVars: any;

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html'
})
export class RootComponent implements OnInit {

  //Action item icon only
  public actionItemWithCallback: ActionItemWithCallback = {
    actionItem: {
      icon: 'hui-icon ic090_home',
    },
    callback: this.onSampleFunctionClick.bind(this)
  }

  public actionItemWithMenu = { icon: 'hui-icon ic090_home', title: '', subtitle: '' }

  companyName: BehaviorSubject<string> = new BehaviorSubject('');
  contactName: BehaviorSubject<string> = new BehaviorSubject('');
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  // private userActivity;
  private userMouseMove: Subject<any> = new Subject();
  public langChangeRequired: boolean = false;
  /* loadComponentsAfterAuth is used to hide the header till the user is authenticated through login */
  public loadComponentsAfterAuth: boolean = false;
  public userName: string = 'User';
  public customHeaderDropDown: Array<any> = [
    {
      name: 'LINKS.ACCESS_AGREEMENT_LINK',
      link: 'https://www.hilti.com/content/hilti/W1/US/en/company/legal-and-footer-information/hilti-online-access-agreement.html',
      linkTarget: '_blank'
    },
    {
      name: 'LINKS.PRIVACY_POLICY_LINK',
      link: 'https://www.hilti.com/content/hilti/W1/US/en/company/legal-and-footer-information/privacy-policy.html',
      linkTarget: '_blank'
    },
    {
      name: 'LINKS.CONTACT_US_LINK',
      link: 'https://www.hilti.com/#contact',
      linkTarget: '_blank'
    }
  ]
  constructor(private authService: AuthService,
    public route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private paramsService: ParamsService,
    private sessionStorageService: SessionStorageService,
    private dataService: DataService
  ) {
    /* This logic has been taken down for now. */
    // this.setTimeoutToLogOut();
    // this.userMouseMove.subscribe((res) => {
    //   /* Logout after 10 mins of inactivity */
    //   this.authService.logout();
    // }
    // );
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dataService.changeBackButtonState(true);
  }

  ngOnInit() {
    /* language change is only for CANADA site. If the URL has '.ca' on it then only language change will be enabled on the header */
    if (window.location.origin.substr(window.location.origin.length - 3, window.location.origin.length) === '.ca') {
      this.langChangeRequired = true;
    } else {
      this.langChangeRequired = false;
    }

    this.changeLanguageLink ();

    this.route.queryParams.subscribe(params => {
      this.persistOriginApplicationURL(params['callback_url']);
    });

    //console.log(this.isLoggedIn.getValue());
    setTimeout(() => {
      this.companyName.next('');
      this.isLoggedIn.next(false);
      //console.log(this.authService.getLogonID());
      if (this.authService.getLogonID()) {
        this.companyName.next(this.authService.getLogonID().CustomerName);
        this.contactName.next(this.authService.getLogonID().ContactFirstName + ' ' + this.authService.getLogonID().ContactLastName);
        //console.log(this.companyName.getValue());
      }
      if (this.authService.isLoggedIn() === true) {
        this.loadComponentsAfterAuth = true;
        let email = this.sessionStorageService.getUserName() ? this.sessionStorageService.getUserName() : 'User';
        this.userName = email.substring(0, email.lastIndexOf("@"));
        this.isLoggedIn.next(true);
      }

    }, 2000);
    }
  /*This logic has been taken down for now. */
  // setTimeoutToLogOut() { 
  //   /* Logout after 10 mins of inactivity */
  //   this.userActivity = setTimeout(() => this.userMouseMove.next(undefined), 600000);
  // }
  // @HostListener('window:mousemove') refreshUserState() {
  //   clearTimeout(this.userActivity);
  //   this.setTimeoutToLogOut();
  // }

  persistOriginApplicationURL(originApplicationURL: string) {
    // If origin url exists, persist it. If not, use the default
    if (originApplicationURL) {
      this.localStorageService.setOriginApplicationURL(originApplicationURL);
    } else {

      if (!this.localStorageService.getOriginApplicationURL() || this.localStorageService.getOriginApplicationURL() === '') {
        this.localStorageService.setOriginApplicationURL(this.paramsService.getParams().SOURCE_APP_URL);
      }
    }
  }

  selectItem(eventArgs: ListItemEvent) {
    //console.log('#' + eventArgs.index + ' selected');
  }
  onSampleFunctionClick(event) {
    //console.log('Item clicked!');
  }

  /**
   * #HNASB-444 - Updating profile links for CA site with 'en' & 'fr' languages
   */ 
  changeLanguageLink () {
    if (window.sessionStorage.country === 'CA') {
      if (window.sessionStorage.language === 'en') {
        this.customHeaderDropDown[0].link = 'https://www.hilti.ca/content/hilti/W1/CA/en/company/legal-and-footer-information/access-agreement.html';
        this.customHeaderDropDown[1].link = 'https://www.hilti.ca/content/hilti/W1/CA/en/company/legal-and-footer-information/privacy-policy.html';
        this.customHeaderDropDown[2].link = 'https://www.hilti.ca/#contact';
      } else {
        this.customHeaderDropDown[0].link = 'https://www.hilti.ca/content/hilti/W1/CA/fr/entreprise/information-legale/entente-dacces.html';
        this.customHeaderDropDown[1].link = 'https://www.hilti.ca/content/hilti/W1/CA/fr/entreprise/information-legale/politique-confidentialite.html';
        this.customHeaderDropDown[2].link = 'https://www.hilti.ca/fr#contact';
      }
    }
  }
}

