import { NgModule } from '@angular/core';
import { BsDropdownModule, ModalModule, BsModalRef } from 'ngx-bootstrap';

@NgModule({
  imports: [
    BsDropdownModule.forRoot(),
    ModalModule.forRoot()
  ],
  exports: [
    BsDropdownModule,
    ModalModule
  ],
  providers: [BsModalRef]
})
export class NgxBootstrapModule {
}
