import {CookieService} from 'ngx-cookie-service';
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

const localeCookieName = 'HC-LOCALE';
const countryCookieName = 'HC-COUNTRY';

@Injectable()
export class HiltiCookieService {


  constructor(private cookieService: CookieService) {
  }

  setLocaleCookie(value: string) {
    this.cookieService.set(localeCookieName, value, 31536000000, '/', environment.production ? '.hilti.com' : 'localhost', true);
  }

  setCountryCookie(value: string) {
    this.cookieService.set(countryCookieName, value, 31536000000, '/', environment.production ? '.hilti.com' : 'localhost', true);
  }

  getLocaleCookie() {
    return this.cookieService.get(localeCookieName);
  }

  getCountryCookie() {
    return this.cookieService.get(countryCookieName);
  }
}
