import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubmittalsCartRoutingModule } from './submittals-cart-routing.module';
import { SubmittalsCartComponent } from './submittals-cart.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SubmittalsCartEmptyComponent } from './components/submittals-cart-empty/submittals-cart-empty.component';
import { SubmittalsCartDeliveryComponent } from './components/submittals-cart-delivery/submittals-cart-delivery.component';
import { DropdownModule, RadioButtonModule } from 'hilti-ui-library';
import { SubmittalsCartSummaryComponent } from './components/submittals-cart-summary/submittals-cart-summary.component'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { SubmittalsCartDeliveryOptionMobComponent } from './components/submittals-cart-delivery-option-mob/submittals-cart-delivery-option-mob.component';


@NgModule({
  declarations: [
    SubmittalsCartComponent,
    SubmittalsCartEmptyComponent,
    SubmittalsCartDeliveryComponent,
    SubmittalsCartSummaryComponent,
    SubmittalsCartDeliveryOptionMobComponent
  ],
  imports: [
    AccordionModule.forRoot(),
    CommonModule,
    SharedModule,
    SubmittalsCartRoutingModule,
    TranslateModule,
    DropdownModule,
    RadioButtonModule
  ],
  exports: [TranslateModule],
  providers: []
})
export class SubmittalsCartModule { }
