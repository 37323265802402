import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { MenuListItem } from 'hilti-ui-library/lib/list-item/model/menu-item-list.model';
import { ListItemEvent } from 'hilti-ui-library/lib/list-item/model/list-item-event.model';
import { delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { BsModalService } from 'ngx-bootstrap';

@Component({
    selector: 'app-submittals-product-document',
    templateUrl: './submittals-product-document.component.html',
    styleUrls: ['./submittals-product-document.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmittalsProductDocumentComponent implements OnInit, OnDestroy {

    public showParentComponent: boolean = true;
    public selectedItemsToGenerateTabs: Array<any> = [];
    public selectedByProductObject: Array<any> = [];
    public imgURL: string = '';
    public selectedProductName: string = '';
    hintTextDropdown = 'Select...';
    public navItemsList: MenuListItem[] = [];
    assetHostName = "";

    constructor(
        private router: Router,
        public activatedRoute: ActivatedRoute,
        private sessionStorageService: SessionStorageService,
        private dataService: DataService,
        public config: LanguageConfig,
        private modalService: BsModalService,

    ) {
        this.dataService.currentSubmittalFlag
            .pipe(delay(0))
            .subscribe(m => {
                if (m === 'createSubmittals') {
                    this.showParentComponent = false
                } else {
                    this.showParentComponent = true
                }
            });
    }

    ngOnInit() {
        this.selectedByProductObject = this.sessionStorageService.getByProductObject();
        /* checked if the sub category step exists on the session object and accordingly displaying the product cateory or subcategory details. */
        if (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) {
            this.selectedItemsToGenerateTabs = this.selectedByProductObject[2].selectedItems;
            let tempUrl = this.selectedByProductObject[1].selectedItems[0].images ? this.selectedByProductObject[1].selectedItems[0].images.APQ_HC_300x300 : '';
            if (tempUrl != '') {
                this.imgURL = this.selectedByProductObject[1].selectedItems[0].assetHostName + '/' + tempUrl;
                this.assetHostName = this.selectedByProductObject[1].selectedItems[0].assetHostName;
            }
            this.selectedProductName = this.selectedByProductObject[1].selectedItems[0].values.CHA_CLASSIFICATION_PUBLICATION_DESC;
            this.selectedByProductObject[2].selectedItems.forEach(products => {
                this.navItemsList.push({ text: products.values.CHA_SHORT_DESCRIPTION, value: products.id })
            });
        } else {
            this.selectedItemsToGenerateTabs = this.selectedByProductObject[3].selectedItems;
            let tempUrl = this.selectedByProductObject[2].selectedItems[0].images ? this.selectedByProductObject[2].selectedItems[0].images.APQ_HC_300x300 : '';
            if (tempUrl != '') {
                this.imgURL = this.selectedByProductObject[2].selectedItems[0].assetHostName + '/' + tempUrl;
                this.assetHostName = this.selectedByProductObject[2].selectedItems[0].assetHostName;
            }
            this.selectedProductName = this.selectedByProductObject[2].selectedItems[0].values.CHA_CLASSIFICATION_PUBLICATION_DESC;
            this.selectedByProductObject[3].selectedItems.forEach(products => {
                this.navItemsList.push({ text: products.values.CHA_SHORT_DESCRIPTION, value: products.id })
            });
        }
    }

    updateValueDropdown(event: ListItemEvent) {
        //assign based on item sent
        this.router.navigate([event.listItem.value], { relativeTo: this.activatedRoute });
    }

    ngOnDestroy(): void {
        this.modalService.hide(1);
    }
}
