import { Injectable } from '@angular/core';
import { AuthorizationEntry } from '../models/authorization-entry.model';

@Injectable({
  providedIn: 'root'
})

export class SessionStorageService {
  private loggedInKey = 'logged_in';
  private accessTokenKey = 'access_token';
  private authorizationEntryKey = 'authorization_entry';
  private country = 'country';
  private language = 'language';
  private userName = 'userName';
  private customerId = 'customer_id';
  private byProductObject = 'by_product_object';
  private byCartProductObject = 'by_cart_product_object';
  private cloudId = 'cloud_id';
  private submittalsSubmitted = 'submittals_submitted';
  private codeVerifier = 'code_verifier';

  getByProductObject(): any[] {
    return JSON.parse(sessionStorage.getItem(this.byProductObject));
  }

  setByProductObject(value: any[]) {
    const tempObj = JSON.stringify(value);
    sessionStorage.setItem(this.byProductObject, tempObj);
  }

  removeByProductObject() {
    sessionStorage.removeItem(this.byProductObject);
  }

  getByCartProductObject(): any[] {
    return JSON.parse(sessionStorage.getItem(this.byCartProductObject));
  }

  setByCartProductObject(value: any[]) {
    const tempObj = JSON.stringify(value);
    sessionStorage.setItem(this.byCartProductObject, tempObj);
  }

  removeByCartProductObject() {
    sessionStorage.removeItem(this.byCartProductObject);
  }

  getIsSubmittalsSubmitted(): boolean {
    return JSON.parse(sessionStorage.getItem(this.submittalsSubmitted));
  }

  setIsSubmittalsSubmitted(value: boolean) {
    const tempObj = JSON.stringify(value);
    sessionStorage.setItem(this.submittalsSubmitted, tempObj);
  }

  removeIsSubmittalsSubmitted(): void {
    sessionStorage.removeItem(this.submittalsSubmitted);
  }

  getCountry(): string {
    return sessionStorage.getItem(this.country);
  }

  setCountry(value: string) {
    sessionStorage.setItem(this.country, value);
  }

  removeCountry() {
    sessionStorage.removeItem(this.country);
  }
  getLanguage(): string {
    return sessionStorage.getItem(this.language);
  }

  setLanguage(value: string) {
    sessionStorage.setItem(this.language, value);
  }

  removeLanguage() {
    sessionStorage.removeItem(this.language);
  }
  getUserName(): string {
    return sessionStorage.getItem(this.userName);
  }

  setUserName(value: string) {
    sessionStorage.setItem(this.userName, value);
  }

  removeUserName() {
    sessionStorage.removeItem(this.userName);
  }
  getCustomerId(): string {
    return sessionStorage.getItem(this.customerId);
  }

  setCustomerId(value: string) {
    sessionStorage.setItem(this.customerId, value);
  }

  removeCustomerId() {
    sessionStorage.removeItem(this.customerId);
  }

  getIsLoggedIn(): string {
    return sessionStorage.getItem(this.loggedInKey);
  }

  setIsLoggedIn(value: string) {
    sessionStorage.setItem(this.loggedInKey, value);
  }

  removeIsLoggedIn() {
    sessionStorage.removeItem(this.loggedInKey);
  }

  getAccessToken(): string {
    return sessionStorage.getItem(this.accessTokenKey);
  }

  setAccessToken(value: string) {
    sessionStorage.setItem(this.accessTokenKey, value);
  }

  removeAccessToken() {
    sessionStorage.removeItem(this.accessTokenKey);
  }

  getCodeVerifierToken(): string {
    return sessionStorage.getItem(this.codeVerifier);
  }

  setCodeVerifierToken(value: string) {
    sessionStorage.setItem(this.codeVerifier, value);
  }

  removeCodeVerifierToken() {
    sessionStorage.removeItem(this.codeVerifier);
  }

  getAuthorizationEntry(): AuthorizationEntry {
    const authorizationEntry: string = sessionStorage.getItem(this.authorizationEntryKey);
    return JSON.parse(authorizationEntry) || {};
  }

  setAuthorizationEntry(value: AuthorizationEntry) {
    sessionStorage.setItem(this.authorizationEntryKey, JSON.stringify(value));
  }

  removeAuthorizationEntry() {
    sessionStorage.removeItem(this.authorizationEntryKey);
  }

  // Captcha handling
  getCaptcha(): string {
    return sessionStorage.getItem('captcha');
  }

  setRandomCaptcha(): string {
    const number = this.generateRandomStateNumber();
    sessionStorage.setItem('captcha', number);
    return number;
  }

  removeCaptcha(): void {
    sessionStorage.removeItem('captcha');
  }

  getCloudId(): string {
    return sessionStorage.getItem(this.cloudId);
  }

  setCloudId(value: string) {
    sessionStorage.setItem(this.cloudId, value);
  }

  removeCloudId() {
    sessionStorage.removeItem(this.cloudId);
  }

  private generateRandomStateNumber(): string {
    const min = 10000;
    const max = 99999;
    const number = Math.floor(Math.random() * (max - min + 1)) + min;
    return String(number);
  }

  removeAllSessions(): void {
    this.removeUserName();
    this.removeByProductObject();
    this.removeByCartProductObject();
    this.removeAccessToken();
    this.removeAuthorizationEntry();
    this.removeCaptcha();
    this.removeCountry();
    this.removeCustomerId();
    this.removeIsLoggedIn();
    this.removeLanguage();
    this.removeCloudId();
    this.removeIsSubmittalsSubmitted();
    this.removeCodeVerifierToken();
    sessionStorage.removeItem('changeLanguage');
  }
}
