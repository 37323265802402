import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { AuthComponent } from './auth/auth-login/auth.component';
import { NavigationGuard } from './shared/services/navigation.guard';

const hiltiRoutes: Routes = [
  /* Authentication routes */
  { path: 'oauth_callback', component: AuthComponent },

  {
    path: 'by-products',
    canActivate: [AuthGuardService],
    canDeactivate: [NavigationGuard],
    loadChildren: () => import(`./modules/index`).then(m => m.SubmittalsByProductModule)
  },
  {
    path: 'cart',
    canActivate: [AuthGuardService],
    canDeactivate: [NavigationGuard],
    loadChildren: () => import(`./modules/index`).then(m => m.SubmittalsCartModule)
  },
  {
    path: 'by-products/:id/product-category-section/:pcsid',
    canActivate: [AuthGuardService],
    canDeactivate: [NavigationGuard],
    loadChildren: () => import(`./modules/index`).then(m => m.SubmittalsProductSectionsModule)
  },
  {
    path: 'by-products/:id/product-category/:pcsid/product-document/:DocId',
    canActivate: [AuthGuardService],
    canDeactivate: [NavigationGuard],
    loadChildren: () => import(`./modules/index`).then(m => m.SubmittalsProductSectionsModule)
  },
  { path: '', redirectTo: 'by-products', pathMatch: 'full' },
  { path: '**', redirectTo: 'by-products' }
];


@NgModule({
  imports: [RouterModule.forRoot(hiltiRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
