import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubmittalsByProductRoutingModule } from './submittals-by-product-routing.module';
import { SubmittalsByProductComponent } from './submittals-by-product.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SubmittalsByProductResolver } from './submittals-by-product.resolver.service';

@NgModule({
  declarations: [SubmittalsByProductComponent],
  imports: [
    CommonModule,
    SubmittalsByProductRoutingModule,
    SharedModule,
    TranslateModule
  ],
  providers: [SubmittalsByProductResolver]
})
export class SubmittalsByProductModule { }
