import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataService } from 'src/app/shared/services/data.service';
import { CartService } from '../../services/cart.service';

@Component({
    selector: 'app-submittals-cart-delivery-option-mob',
    templateUrl: './submittals-cart-delivery-option-mob.component.html',
    styleUrls: ['./submittals-cart-delivery-option-mob.component.scss']
})

export class SubmittalsCartDeliveryOptionMobComponent implements OnInit {
    private coverPageIdentifier: File;
    public uploadSuccess: boolean;
    public uploadOnlyPdfErrorShow: boolean = false;
    private fileToUpload: File = null;
    @Input() optionsForm: FormGroup;

    constructor(
        private dataService: DataService,
        private translate: TranslateService,
        private cartService: CartService
    ) { }

    ngOnInit() { }

    emailSelected(e): void {
        if (e === 'emailSub') {
            this.optionsForm.addControl('userEmailInput',
                new FormControl('', [
                    Validators.required,
                    this.cartService.emailCustomValidator
                ]))
        } else {
            this.optionsForm.removeControl('userEmailInput')
        }
    }

    customCoverSelected(e) {
        this.uploadSuccess = undefined;
        this.uploadOnlyPdfErrorShow = false;
        if (e === 'customCoverPage') {
            this.optionsForm.addControl('customCoverInput',
                new FormControl('', [
                    Validators.required
                ]))
        } else {
            this.optionsForm.removeControl('customCoverInput')
        }
    }

    /* istanbul ignore next */
    uploadFile(files: FileList) {
        this.uploadOnlyPdfErrorShow = false;
        this.uploadSuccess = undefined;

        if (typeof files.item == 'function') {
            this.fileToUpload = files.item(0);
            /* only PDF less than 5MB is allowed to be uploaded */
            if (this.fileToUpload.type == 'application/pdf' && this.fileToUpload.size < 5000001) {
                this.coverPageIdentifier = this.fileToUpload;
                this.dataService.changeCustomCoverPageFile(this.coverPageIdentifier);
            } else {
                /* cleared the file from the input if any condition is failed */
                this.optionsForm.patchValue({ 'customCoverInput': '' });
                this.uploadOnlyPdfErrorShow = true;
                this.uploadSuccess = undefined;
            }
        }
    }

    updateEmail(event: string) {
        this.optionsForm.patchValue({ 'userEmailInput': event });
    }
}
