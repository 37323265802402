import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-hilti-custom-modal',
  templateUrl: './hilti-custom-modal.component.html',
  styleUrls: ['./hilti-custom-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HiltiCustomModalComponent implements OnInit {

  public title: string = 'Modal Title';
  public imgURL: string = '';
  public buttonTitle: string = 'Ok';
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
}
