import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor() { }

    private submittalFlag = new BehaviorSubject('default flag');
    private stepperObjectGlobal = new BehaviorSubject([]);
    private byProductObject = new BehaviorSubject([]);
    private sectionsOrProductsObject = new BehaviorSubject([]);
    private customCoverPageFile = new BehaviorSubject(File);
    private backButtonState = new BehaviorSubject(true);

    currentSubmittalFlag = this.submittalFlag.asObservable();
    currentStepperObjectGlobal = this.stepperObjectGlobal.asObservable();
    currentByProductObject = this.byProductObject.asObservable();
    currentSectionsOrProductsObject = this.sectionsOrProductsObject.asObservable();
    currentCustomCoverPageFile = this.customCoverPageFile.asObservable();
    currentBackButtonState = this.backButtonState.asObservable();

    changeSubmittalFlag(message: string) {
        this.submittalFlag.next(message);
    }
   
    changeStepperObjectGlobal(stepobj: any[]) {
        this.stepperObjectGlobal.next(stepobj);
    }

    changeByProductObject(byProdObj: any[]) {
        this.byProductObject.next(byProdObj);
    }

    changeSectionsOrProductsObject(secOrProd: any = {}) {
        this.sectionsOrProductsObject.next(secOrProd);
    }

    changeCustomCoverPageFile(file: any) {
        this.customCoverPageFile.next(file);
    }

    changeBackButtonState(state: boolean) {
        this.backButtonState.next(state);
    }

    /**
 * @method changeStepperStepInfo uses to This logic is to set the stepper values. On click of next the stepper 
 * progress will be incresed. We are also checking if the user has clicked on already passed stepper. If so the 
 * current step will be selected and will be increased by one on click of next, in which case stepper progress 
 * will be at the same value
 * @param stepperObject refers stepper object
 * @param valueObject refers value object
 */
    public changeStepperStepInfo(stepperObject: any = [], valueObject: any = []) {
        if (stepperObject.length) {
            if (stepperObject[0].stepperCurrentCount) {
                if (stepperObject[0].stepperCurrentCount < stepperObject[0].stepperProgressCount) {
                    stepperObject = stepperObject.map(v => ({ ...v, stepperCurrentCount: valueObject[0].stepValue + 1 }));
                } else {
                    stepperObject = stepperObject.map(v => ({ ...v, stepperCurrentCount: undefined }));
                    if (stepperObject.length <= stepperObject[0].stepperProgressCount) {
                        stepperObject = stepperObject.map(v => ({ ...v, stepperProgressCount: 0 }));
                    } else {
                        stepperObject = stepperObject.map(v => ({ ...v, stepperProgressCount: valueObject[0].stepValue }));
                    }
                }
            } else {
                if (stepperObject.length <= stepperObject[0].stepperProgressCount) {
                    stepperObject = stepperObject.map(v => ({ ...v, stepperProgressCount: 0 }));
                } else {
                    stepperObject = stepperObject.map(v => ({ ...v, stepperProgressCount: valueObject[0].stepValue }));
                }
            }
        }  
        return stepperObject;
    }

    /**
     * @method onAfterContentChecked use to update stepper for various screens
     * @param accordionsArray refers selected by products array
     * @param stepperObject refers stepper object details
     * @param activeStepper refers active stepper name
     * @param routerURL refers active stepper url for navigation view
     */
    public onAfterContentChecked (accordionsArray: Array<any>, stepperObject: Array<any>, activeStepper: string, routerURL: string) {
        let stepNoToSet: number;

        if (activeStepper === 'STEPPER.SUMMARY') {
            stepNoToSet = 4;
        } else if (activeStepper === 'STEPPER.OPTIONS') {
            stepNoToSet = 3;
        } else if (activeStepper === 'STEPPER.DOCUMENTS') {
            stepNoToSet = 2;
        } else if (activeStepper === 'STEPPER.PRODUCTS' || activeStepper === 'STEPPER.SECTIONS') {
            stepNoToSet = 1;
        }

        if (accordionsArray.filter(obj => obj.TITLE === 'STEPPER.SECTIONS').length !== 0 && activeStepper !== 'STEPPER.SECTIONS') {
            ++stepNoToSet;
        }
        if (stepperObject && stepperObject.length > 0 && stepperObject[0].stepperProgressCount < stepNoToSet) {
            stepperObject = stepperObject.map(v => ({ ...v, stepperProgressCount: stepNoToSet }));
            
            this.changeStepperObjectGlobal(stepperObject);
        }
        
        if (stepperObject && stepperObject.length > 0) {
            const tempStep = stepperObject.filter(obj => { return obj.title === activeStepper });
            if (!stepperObject[tempStep[0].stepValue].routeUrl) {
                stepperObject[tempStep[0].stepValue].routeUrl = stepperObject[(tempStep[0].stepValue) - 1].routeUrl + routerURL; 
                this.changeStepperObjectGlobal(stepperObject);
            }
        }
        return stepperObject;
    }

}
