import { Component, OnInit, OnDestroy, AfterContentChecked, HostListener } from '@angular/core';
import { DataService } from 'src/app/shared/services/data.service';
import { MenuListItem } from 'hilti-ui-library/lib/list-item/model/menu-item-list.model';
import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'src/app/shared/services/session-storage.service';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { ParamsService } from 'src/app/shared/services/params.service';
import { Subscription } from 'rxjs';
import { LanguageConfig } from 'src/app/shared/models/language-config.model';
import { GoogleTagManagerService } from 'src/app/shared/services/google-tag-manager.service';
import { CartService } from '../submittals-cart/services/cart.service';

@Component({
    selector: 'app-submittals-product-document-list',
    templateUrl: './submittals-product-document-list.component.html',
    styleUrls: ['./submittals-product-document-list.component.scss']
})
export class SubmittalsProductDocumentListComponent implements OnInit, AfterContentChecked, OnDestroy {

    public stepperObject: Array<any> = [];
    public collapseAllacc: boolean = false;
    public spinner$: boolean = false;
    public documentTabs: Array<any> = [];
    public selectedtab: string = '';
    public samplemMenuItemsList: MenuListItem[] = [];
    public accordionsArray: Array<any> = [];
    public routedDocId: string = '';
    public selectedByProductObject: Array<any> = [];
    private observeRoute: Subscription;
    private observeStepperObj: Subscription;
    private params: any = {};
    private checkBoxGroup: any = {};
    public documentsForm = new FormGroup({});
    public productDataWrap: any = [];
    accordionDisplay = false;

    public selectedByCartProductObject: Array<any> = [];

    ngAfterContentChecked(): void {
        let routerURL = '/product-document/' + this.activatedRoute.snapshot.params['DocId'];

        this.stepperObject = this.dataService.onAfterContentChecked(this.selectedByProductObject, this.stepperObject, 'STEPPER.DOCUMENTS', routerURL);
    }

    constructor(
        public activatedRoute: ActivatedRoute,
        private router: Router,
        private httpRequestService: HttpRequestService,
        private dataService: DataService,
        private paramsService: ParamsService,
        private sessionStorageService: SessionStorageService,
        public config: LanguageConfig,
        private tagService: GoogleTagManagerService,
        private cartService: CartService
    ) {
        this.params = paramsService.getParams() || {};
        this.observeStepperObj = this.dataService.currentStepperObjectGlobal
            .pipe(delay(0))
            .subscribe(c => {
                this.stepperObject = c;
            });
        this.observeRoute = this.activatedRoute.params.subscribe(params => {
            this.routedDocId = params['DocId'];
            if (this.loadDocumentTabs()) {
                setTimeout(() => { this.switchAccordions() }, 300);
            }
        })
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.accordionDisplay = this.resetAccordionDisplay();
    }

    resetAccordionDisplay(): boolean {
        if (window.innerWidth <= 600) {
            return true;
        }
        return false;
    };

    ngOnInit() {
        this.selectedByProductObject = this.sessionStorageService.getByProductObject();
        if(this.selectedByProductObject.length===0){
            this.router.navigate(['/by-products']);
        }
        this.loadDocumentOptionsFromAPI();
        this.accordionDisplay = this.resetAccordionDisplay();
    }

    /* Load document tabs such as 'Approvals/Certificates' dynamically from config file */
    loadDocumentTabs() {
        this.selectedByProductObject = this.sessionStorageService.getByProductObject();
        this.selectedByCartProductObject = this.sessionStorageService.getByCartProductObject() || [];
        let stepperNumber = (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) ? 2 : 3;
        this.accordionsArray = this.selectedByProductObject[stepperNumber] ? this.selectedByProductObject[stepperNumber].selectedItems : [];

        if (this.documentTabs.length > 0) {
            return true;
        } else {
            const url = this.selectedByProductObject[0].id === "CLS_FIRESTOP_PROTECTION_7131" ? '/document-types2.json' : '/document-types.json';
            /** We do not need to load the same tabs every time we route to different product */
            this.httpRequestService.get(this.params.BY_DOCUMENT_CONFIG_FILE + url, {})
                .subscribe((obj => {
                    obj.forEach(tabname => {
                        this.documentTabs.push(tabname.docName)
                    });
                    this.selectedtab = this.documentTabs[0];

                    return true;
                }),
                    (err => {
                        return false;
                    }))
        }
    }

    /* switchAccordions according to the selected tab */
    switchAccordions() {
        this.collapseAllacc = false;

        let element = document.getElementById('accordBtn' + this.routedDocId);

        if (element) {
            element.click();
        }
    }

    loadDocumentOptionsFromAPI() {
        this.spinner$ = true;
        this.productDataWrap = [];
        let tempProductData: any = [];
        let tempProductDataWrap: any = [];
        let productIds: any = [];
        this.accordionsArray.forEach(acc => {
            productIds.push(acc.id)
        });

        this.httpRequestService.getWithToken(this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/documents', { productIds: productIds.toString() })
            .subscribe(((obj: any) => {
                tempProductDataWrap = [];
                obj.productDataWrapper.forEach(proDataWrap => {
                    tempProductData = [];
                    proDataWrap.productData.forEach((doc, i) => {
                        /* creating attribute pdfLink for the pdf link */
                        doc.pdfLink = obj.assetHostName + '/' + (doc.assetPushLocations ? doc.assetPushLocations.APQ_HC_RAW : '');
                        doc.isDisabled = false;

                        // Select document DX category ICC-ES, ICCESR document type
                        if (doc.subTypeBucket == 'type1' && doc.values['CHD_ASSET_SUB_TYPE_ID'] !== "CSFM") {
                            if (this.selectedByProductObject[0].id === "CLS_FASTENER_7135" ||
                                this.selectedByProductObject[0].id === "CLS_DIRECT_FASTENING") {
                                if (doc.iccFlag === true) {
                                    doc.value = true;

                                }
                            } else {
                                doc.value = true;
                            }
                        }

                        if (doc.subTypeBucket == 'type2') {
                            doc.value = true;
                            doc.isDisabled = true;
                        }
                        if (doc.subTypeBucket == 'type3') {
                            doc.value = false;
                        }
                        if (doc.subTypeBucket == 'type4') {
                            doc.value = false;
                        }
                        if (doc.subTypeBucket) {
                            tempProductData.push(doc)
                        }
                    })
                    tempProductDataWrap.push({
                        productData: tempProductData,
                        productId: proDataWrap.productId,
                        subType1Count: proDataWrap.subType1Count,
                        subType2Count: proDataWrap.subType2Count,
                        subType3Count: proDataWrap.subType3Count,
                        subType4Count: proDataWrap.subType4Count
                    });
                })
                this.productDataWrap = tempProductDataWrap;
                this.createFormControl(this.productDataWrap)
                this.spinner$ = false;
            }),
                (err => {
                    this.spinner$ = false;
                    this.documentsForm.setErrors({ 'invalid': true });
                }));
    }

    createFormControl(productDataWrap: any = []) {
        productDataWrap.forEach((checkBoxArray, index) => {
            if (checkBoxArray.productData && checkBoxArray.productData.length > 0) {
                checkBoxArray.productData.forEach((item, i) => {
                    item._id = item._id + index;
                    checkBoxArray.productData[i].value = item.value;
                    this.checkBoxGroup[item._id] = new FormControl({ value: item.value, disabled: item.isDisabled })
                })
            }
        })
        this.documentsForm = new FormGroup(this.checkBoxGroup, this.requireOneCheckboxesToBeCheckedValidator());
    }

    showSidePads(itemChecked, value) {
        let removeItemid = {};
        removeItemid[itemChecked._id] = value;
        this.documentsForm.patchValue(removeItemid);
        this.saveUserSelectedDataInSessionStorage();
    }

    navigateToOptions() {
        /* This logic is to set the stepper values. On click of next the stepper progress will be incresed.
        We are also checking if the user has clicked on already passed stepper. If so the current step will be selected and will be increased by one on click of next, 
        in which case stepper progress will be at the same value */
        let tempDocStep = this.stepperObject.filter(obj => { return obj.title === 'STEPPER.DOCUMENTS' });
        if (tempDocStep[0] && tempDocStep[0].stepValue) {
            this.stepperObject = this.dataService.changeStepperStepInfo(this.stepperObject, tempDocStep)

        }
        this.dataService.changeStepperObjectGlobal(this.stepperObject);
        this.saveUserSelectedDataInSessionStorage();

        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step6',
            'pageName': 'Submittal Options'
        }, this.selectedByProductObject);

        // this.router.navigate(['create-submittals'], { relativeTo: this.activatedRoute });
        this.router.navigate(['/cart']);
    }

    saveUserSelectedDataInSessionStorage() {
        this.productDataWrap.forEach((wrap, i) => {
            let approvalCertificatesPDFArray = [];
            let productSafetyPDFArray = [];
            let greenPDFArray = [];
            let generalInformationPDFArray = [];

            let stepperNumber = (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) ? 2 : 3;

            this.selectedByProductObject[stepperNumber].selectedItems.forEach(selProd => {
                if (wrap.productId == selProd.id) {
                    this.productDataWrap[i].name = selProd.values.CHA_SHORT_DESCRIPTION
                }
            })

            wrap.productData.forEach(doc => {
                if (doc.subTypeBucket == 'type1') {
                    approvalCertificatesPDFArray.push(doc);
                }
                if (doc.subTypeBucket == 'type2') {
                    productSafetyPDFArray.push(doc);
                }
                if (doc.subTypeBucket == 'type3') {
                    greenPDFArray.push(doc);
                }
                if (doc.subTypeBucket == 'type4') {
                    generalInformationPDFArray.push(doc);
                }
            });

            if (this.selectedByProductObject[0].id === "CLS_FIRESTOP_PROTECTION_7131") {
                this.productDataWrap[i].selectedDocuments = [
                    {
                        TITLE: this.documentTabs[0],
                        selectedPDFs: productSafetyPDFArray
                    },
                    {
                        TITLE: this.documentTabs[1],
                        selectedPDFs: approvalCertificatesPDFArray
                    },
                    {
                        TITLE: this.documentTabs[2],
                        selectedPDFs: greenPDFArray
                    },
                    {
                        TITLE: this.documentTabs[3],
                        selectedPDFs: generalInformationPDFArray
                    },
                ]
            }
            else {
                this.productDataWrap[i].selectedDocuments = [
                    {
                        TITLE: this.documentTabs[0],
                        selectedPDFs: approvalCertificatesPDFArray
                    },
                    {
                        TITLE: this.documentTabs[1],
                        selectedPDFs: productSafetyPDFArray
                    },
                    {
                        TITLE: this.documentTabs[2],
                        selectedPDFs: greenPDFArray
                    },
                    {
                        TITLE: this.documentTabs[3],
                        selectedPDFs: generalInformationPDFArray
                    },
                ]
            }

        })
        /* checked if the sub category step exists on the session object and accordingly saving the user selected document list in the same object */
        let stepper = (this.selectedByProductObject.filter(obj => { return obj.TITLE == 'STEPPER.SECTIONS' }).length == 0) ? 3 : 4;

        let tempByProductObject = {
            TITLE: 'STEPPER.DOCUMENTS',
            accId: stepper,
            selectedItems: this.productDataWrap
        };

        this.selectedByProductObject.splice(stepper, this.selectedByProductObject.length);

        this.selectedByProductObject.push(tempByProductObject);

        this.sessionStorageService.setByProductObject(this.selectedByProductObject);

        this.selectedByCartProductObject.push(this.selectedByProductObject);

        this.sessionStorageService.setByCartProductObject(this.selectedByCartProductObject);
        this.cartService.updateCart();
    }

    /** Invoke a click on the checkbox to unchecked items from the side box */
    removeItemFromList(itemToRemove) {
        let element = document.getElementById(itemToRemove._id);

        if (element) {
            element.click();
        }
    }

    /* ge type index for the table documents to display*/
    getTypeIndex(index) {
        if (this.selectedByProductObject[0].id === "CLS_FIRESTOP_PROTECTION_7131") {
            return index === 1 ? 2 : index === 2 ? 1 : index;
        }
        else {
            return index;
        }
    }

    /* 'requireOneCheckboxesToBeCheckedValidator' validator function to check if atleast one checkbox is selected */
    requireOneCheckboxesToBeCheckedValidator(minRequiredCount = 1): ValidatorFn {
        return function validate(formGroup: FormGroup) {
            let checked = 0;
            Object.keys(formGroup.controls).forEach(key => {
                const control = formGroup.controls[key];
                if (control.value === true) {
                    checked++;
                }
            });
            if (checked < minRequiredCount) {
                return {
                    requireOneCheckboxToBeChecked: true,
                };
            }
            return null;
        };
    }

    onClickPDFLink(pdfLinkId: string) {
        this.tagService.setGMTDataLayer({
            'stepNumber': 'Step5',
            'pageName': 'Documents',
            'event': 'document_preview',
            'eventCategory': 'Submittals',
            'eventAction': 'Document Preview',
            'eventLabel': pdfLinkId
        });
    }

    ngOnDestroy(): void {
        this.observeStepperObj.unsubscribe();
        this.observeRoute.unsubscribe();
    }
}
