import { Resolve } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequestService } from 'src/app/shared/services/http-request.service';
import { ParamsService } from 'src/app/shared/services/params.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubmittalsByProductResolver implements Resolve<Observable<string>> {
    private params: any = {};
    constructor(
        private httpRequestService: HttpRequestService,
        private paramsService: ParamsService,

    ) {
        this.params = paramsService.getParams() ? paramsService.getParams() : {};
    }
    resolve(
    ): Observable<any> {
        return this.httpRequestService.getWithToken(this.params.SERVICE_BASE_URL + '/submittaldocuments/v1/chapters').pipe(
            map(res => res),
            catchError(error => {
                return of({ error: error })
            })
        )
    }
}
