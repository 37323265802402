import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-submittals-cart-empty',
    templateUrl: './submittals-cart-empty.component.html',
    styleUrls: ['./submittals-cart-empty.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SubmittalsCartEmptyComponent implements OnInit {
    constructor(
        private translate: TranslateService
    ) { }

    ngOnInit() { }

}
