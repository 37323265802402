import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { LogoutService } from '../services/logout.service';

@Component({
    templateUrl: './session-timeout-modal.component.html',
    styleUrls: ['./session-timeout-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SessionTimeoutModalComponent {

    header: string;
    title: string;
    message: string;
    positiveButtonName: string;
    positiveButtonHandler: () => void;

    constructor(
        public bsModalRef: BsModalRef,
        private translate: TranslateService,
        private logoutService: LogoutService,
        private tagService: GoogleTagManagerService,
        ) {
    }

    positiveButtonClick() {
        if (this.positiveButtonHandler) {
            this.positiveButtonHandler();
        }

        this.bsModalRef.hide();
    }

    positiveButtonClose(){
        this.bsModalRef.hide();
        this.Logout() 
    }
    
    Logout() {
        
        this.tagService.setGMTDataLayer({
            'event': 'navigation',
            'eventCategory': 'Submittals',
            'eventAction': 'Navigation',
            'eventLabel': 'Logout'
        });

        this.logoutService.logout();
    }
}
